const authPlugin = {
    key: 'auth',

    pluginBehavior: (andels, $) => {
        authPlugin.setupLoginModal();

        $('#registration button[type="submit"]').on('click', function () {
            const reroute = $(this).data('redirect');

            andels.plugins.utils.trackUmamiEvent('Sign-up')
            if(reroute !== undefined && reroute.length) {
                $("<input />").attr("type", "hidden")
                    .attr("name", "reroute")
                    .attr("value", reroute)
                    .appendTo("form#registration");
            }
        });
    },

    processNewCorporationAddresses: () => {
        $('#dawa_address_corporation_creation').siblings('.invalid-feedback').text('')
        $('#dawa_address_corporation_creation').removeClass('is-invalid');

        let corporationCreationAddresses = {};
        $('#dawa_corporation_creation_input_results input').each(function (index, value) {
            corporationCreationAddresses[$(value).attr('name')] = $(value).val();
        });

        $.ajax({
            type: 'POST',
            url: `/corporation/processAddreses`,
            data: corporationCreationAddresses,
            success(data) {
                if(data.addressPointsHtml !== undefined) {
                    $('#new_corporation_user_address_point_id').html(data.addressPointsHtml)
                }
                if(data.corpAddressesHtml !== undefined) {
                    $('[data-content="corporation_addresses"]').html(data.corpAddressesHtml)
                }
            },
            error(data) {
                if(data.responseJSON.error !== undefined) {
                    $('#dawa_address_corporation_creation').siblings('.invalid-feedback').text(data.responseJSON.error)
                    $('#dawa_address_corporation_creation').addClass('is-invalid');
                }
            }
        });
    },
    setupLoginModal: () => {
        let modalAllowed = true;
        if(localStorage.getItem('disable_login_modal')) {
            const modalState = JSON.parse(localStorage.getItem('disable_login_modal'));
            modalAllowed = modalState.timestamp < Date.now();
        }

        if($('#loginModal').length && modalAllowed) {
            $('#loginModal').on('hidden.bs.modal', function () {
                localStorage.setItem('disable_login_modal', JSON.stringify({'timestamp': Date.now() + 300000}));
            });
            $('#loginModal').modal('show');
        }


    },

};
module.exports = authPlugin;
