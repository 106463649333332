$.fn.scrollBottomsInverse = function () {
    const y = Math.ceil(this.scrollTop());
    const z = Math.ceil(this.height())
    return this.prop('scrollHeight') - z + y;
};
const notificationsPlugin = {
    isEditing: false,
    messageBeforeEdit: null,
    key: 'notifications',
    archiveActions: {
        ADD: 'add',
        REMOVE: 'remove'
    },

    pluginBehavior: (andels, $) => {
        notificationsPlugin.from = 1;
        notificationsPlugin.notifications();
        notificationsPlugin.readNotifications();
        notificationsPlugin.messages();
        notificationsPlugin.chooseMember(andels);
        notificationsPlugin.setNotify(andels);

        if ($('.messages__content').length) {
            let height;
            if (parseInt($(window).height()) > 800) {
                height = parseInt($(window).height()) - parseInt($('.container__general--notifications').height()) - $('#app>nav').height() - 150 - parseInt($('.footer__content').height());
            } else {
                height = 500;
            }
            $('.messages__content').css('height', `${height}px`)
        }

        $('.message-form').find('textarea').on('keypress', function (event) {
            if (event.keyCode === 13 && $(this).val().replace(/(\r\n|\n|\r)/gm, "").length > 0) {
                notificationsPlugin.storeNotification();
            }
        });
        $('.business-message-form').find('textarea').on('keypress', function (event) {
            if (event.keyCode === 13 && $(this).val().replace(/(\r\n|\n|\r)/gm, "").length > 0) {
                notificationsPlugin.storeBusinessNotification();
            }
        });
        $('.message-form .not-btn').on('click', function () {
            notificationsPlugin.storeNotification();
        });
        $('.business-message-form .not-btn').on('click', function () {
            notificationsPlugin.storeBusinessNotification();
        });

        $('#openDialog, #contactSeller').on('click', function () {
            $('#messageBox').show();
            $('#messageBox textarea').focus();
        });
        $('#closeMessageBox').on('click', function () {
            $('#messageBox').hide();
        });

        notificationsPlugin.scrollNotificationList();

        if ($('#notifications_container').find('.unread').length) {
            $('#notifications').addClass('has-new');
        }
        if ($('#messages_container').find('.unread').length) {
            $('#messages').addClass('has-new');
        }

        $('.messages__participants--controls a').on('click', function () {
            const target = $(this).data('target');
            $('.messages__participants--controls a').removeClass('messages__participants--controls-active')
            $(this).addClass('messages__participants--controls-active');
            $('.messages__participants--list').removeClass('active');
            if (target === 'active') {
                $('#participants').addClass('active');
                $('[name="message"]').removeAttr('disabled');
            } else if (target === 'archived') {
                $('#participants-archived').addClass('active');
                $('[name="message"]').attr('disabled', 'disabled');
            }
        });

        $('.member__add-to-archive').on('click', function (event) {
            event.stopPropagation();
            let conversation = $(this).parents('.member');
            let conversationId = conversation.data('id');
            if (conversationId !== undefined) {
                notificationsPlugin.manageArchive(notificationsPlugin.archiveActions.ADD, conversationId, conversation);
            }
        });

        $('.member__remove-from-archive').on('click', function (event) {
            event.stopPropagation();
            let conversation = $(this).parents('.member');
            let conversationId = conversation.data('id');
            if (conversationId !== undefined) {
                notificationsPlugin.manageArchive(notificationsPlugin.archiveActions.REMOVE, conversationId, conversation);
            }
        });

        $('#message-form--container').find('.visible textarea').focus();

        notificationsPlugin.initMessageMenu();
    },
    setNotify: (andels) => {
        $('.notifyUser').on('click', function () {
            andels.plugins.utils.attacheSpinner();
            const type = $(this).data('type');
            const id = $(this).data('id');
            const notify = $(this).data('notify');
            $.ajax({
                type: 'POST',
                url: `/${type}/notify/${id}/${notify}`,
                success: function () {
                    window.location.reload();
                },
                error: function (data) {
                    $('.alert-danger').remove();
                    $('.content__center--empty').prepend(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                    andels.plugins.utils.removeSpinner();
                },
            });
        });
    },
    scrollNotificationList: () => {
        if ($('.messages__list').length) {
            $('.messages__list').on('scroll', function () {
                if ($('.messages__list').scrollBottomsInverse() == 0 && notificationsPlugin.from > 0) {
                    let id = $('.message-form #recipient').val();
                    let url = '/dialog';

                    if (!id) {
                        id = $('.chat-form #chat_id').val();
                        url = '/chat_dialog';
                    }

                    if (!id) {
                        id = $('.business-message-form #business_chat_id').val();
                        url = '/business_chat_dialog';
                    }

                    andels.plugins.utils.attacheSpinner();
                    $.ajax({
                        type: 'POST',
                        url: url,
                        data: {id: id, from: notificationsPlugin.from},
                        success: function (data) {
                            if (!$.isEmptyObject(data)) {
                                $('.messages__list').append(data);
                                notificationsPlugin.from++;
                            } else {
                                notificationsPlugin.from = -1;
                            }
                            andels.plugins.utils.removeSpinner();
                        },
                        error: function (data) {
                            $('.alert-danger').remove();
                            $('.messages__list').prepend(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                            andels.plugins.utils.removeSpinner();
                        },
                    });
                }
            });
        }

        if ($('.notifications__history').length && $('.notifications__history .notification').length > 19) {
            const historyContainer = $('.notifications__history');

            $(window).on('scroll.notifications', function () {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    const currentPage = historyContainer.data('page');
                    const nextPage = parseInt(currentPage) + 1;

                    andels.plugins.utils.attacheSpinner();
                    $.ajax({
                        url: '/more/notifications/history',
                        data: {elements_page: nextPage},
                        success: function (result) {
                            historyContainer.append(result.html);
                            historyContainer.data('page', nextPage);

                            if(result.cnt < 1) {
                                $(window).off('scroll.notifications');
                            }

                            andels.plugins.utils.removeSpinner();
                        },
                        error: function (data) {
                            andels.plugins.utils.removeSpinner();
                        },
                    });
                }
            });
        }
    },
    storeNotification: () => {
        if ($('#recipient').val()) {
            $.ajax({
                type: 'POST',
                url: `/message/store`,
                data: $('.message-form').serialize(),
                success(data) {
                    $('.messages__list').prepend(data);
                    $('.message-form').find('textarea').val('');
                    $('.message-form').find('.alert.alert-danger').remove();
                },
                error(data) {
                    $('.messages__list').html(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                }
            });
        }
    },
    storeBusinessNotification: () => {
        if ($('#business_chat_id').val()) {
            $.ajax({
                type: 'POST',
                url: '/business_chat_message/store',
                data: $('.business-message-form').serialize(),
                success(data) {
                    $('.messages__list').prepend(data);
                    $('.business-message-form').find('textarea').val('');
                    $('.business-message-form').find('.alert.alert-danger').remove();
                },
                error(data) {
                    $('.messages__list').html(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                }
            });
        } else if ($('#owner_id').val() && $('#owner_type').val()) {
            let announcement_id = $('#announcement_id').val() ? $('#announcement_id').val() : '';
            $.ajax({
                type: 'POST',
                url: '/business_chat_message/create',
                data: {
                    owner_id: $('#owner_id').val(),
                    owner_type: $('#owner_type').val(),
                    announcement_id: announcement_id
                },
                success(chat_id) {
                    $('.business-message-form').find('#business_chat_id').val(chat_id);
                    $.ajax({
                        type: 'POST',
                        url: '/business_chat_message/store',
                        data: $('.business-message-form').serialize(),
                        success(data) {
                            $('.messages__list').prepend(data);
                            $('.business-message-form').find('textarea').val('');
                            $('.business-message-form').find('.alert.alert-danger').remove();
                        },
                        error(data) {
                            $('.messages__list').html(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                        }
                    });
                },
                error(data) {
                    $('.messages__list').html(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                }
            });
        }
    },
    readNotifications: () => {

        $('#notifications').on('click', function () {
            $('#notifications_container a').removeClass('unread');
            $('#notifications').removeClass('has-new');
            $.ajax({
                type: 'POST',
                url: `/notification/read`,
            });
        });
    },
    chooseMember: (andels) => {
        $.each($('.member'), function () {
            notificationsPlugin.clickOnMember($(this), andels);
        });
    },
    clickOnMember: (element, andels) => {
        $(element).on('click', function () {
            let business_chat = $(this).data('id').toString().indexOf('business_chat') >= 0;
            const element = $(this);
            const id = business_chat ? $(this).data('id').split('_')[2] : $(this).data('id');
            const url = business_chat ? '/business_chat_dialog' : '/dialog';
            andels.plugins.utils.attacheSpinner();
            $.ajax({
                type: 'POST',
                url: url,
                data: {id: id},
                success(data) {
                    notificationsPlugin.from = 1;
                    $('.messages__participants--list li').removeClass('active');
                    element.addClass('active');
                    element.find('.unread-mark').remove();
                    element.find('.member__name div').first().removeClass('font-weight-bold');
                    $('.messages__list').html(data);
                    $(".messages__list").animate({scrollTop: $('.messages__list').prop("scrollHeight")}, 1000);
                    if (business_chat) {
                        $('.message-form #recipient').val('');
                        $('.business-message-form #business_chat_id').val(id);
                        $('.business-message-form').show();
                        $('.message-form').hide();
                        $('.business-message-form').find('textarea').focus();

                    } else {
                        $('.business-message-form #business_chat_id').val('');
                        $('.message-form #recipient').val(id);
                        $('.message-form').show();
                        $('.business-message-form').hide();
                        $('.message-form').find('textarea').focus();
                    }
                    $('.chat-form').hide();
                    $('#chat_id').val('');
                    $('.chat').removeClass('active');
                    $('#chatinfo').html(notificationsPlugin.infoTemplate(element));
                    andels.plugins.utils.removeSpinner();
                },
                error(data) {
                    $('.messages__list').html(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                    andels.plugins.utils.removeSpinner();
                }
            });
        });
    },
    infoTemplate: (element) => {
        let avatar = undefined;
        if (element.find('.circle-35 img').length > 0) {
            const src = element.find('.circle-35 img').attr('src');
            avatar = `<img src="${src}" />`;
        } else if (element.find('.circle-35 .user-initials')) {
            const initials = element.find('.circle-35 .user-initials').html();
            avatar = `<span class="user-initials">${initials}</span>`;
        }
        const id = element.data('id');
        const name = element.find('.member__name').children('div:first-child').html();
        const href = `/user/${id}-` + encodeURIComponent(name);
        return `<li class="member" data-id="${id}"><a href="${href}" class="d-flex align-items-center" title="${name}"><div class="circle-24">${avatar}</div><span class="pl-3">${name}</span></a></li>`
    },
    notifications: () => {
        const userTalk = $('#userTalk');
        if (userTalk.length) {
            window.Echo.channel(`user.${userTalk.val()}`)
                .notification((e) => {
                    $('#notifications_container .font-12').remove();
                    $('#notifications').addClass('has-new');
                    const element = $('#notifications_container').find(`a[data-id="${e.type_id}"]`);
                    if (element.length) {
                        const total_element = element.prev('.notification-badge');
                        if (total_element.html() != undefined) {
                            const total = total_element.html();
                            total_element.html(parseInt(total) + 1).removeClass('important-hidden');
                        }
                    } else {
                        $('#notifications_container .notifications__list').prepend(
                            `<div class="position-relative"><span class="notification-badge important-hidden">1</span>${e.element}</div>`);
                    }
                });
        }
    },
    messages: () => {
        const userTalk = $('#userTalk');
        if (userTalk.length) {
            window.Echo.channel(`message.${userTalk.val()}`)
                .notification((e) => {
                    $('#messages_container .font-12').remove();
                    $('#messages').addClass('has-new');
                    let avatar = e.avatar_set ? `<img src="${e.src}" />` : `<span class="user-initials">${e.initials}</span>`;
                    const element = $('#messages_container').find(`a[href="${e.url}"]`);
                    if (element.length) {
                        const total = element.find('.notifications_details .badge.badge-info').html();
                        if (total == undefined) {
                            element.find('.notifications_details').prepend('<span class="badge badge-info">1</span>');
                        } else {
                            element.find('.notifications_details .badge.badge-info').html(parseInt(total) + 1);
                        }
                    } else {
                        $('#messages_container').prepend(`<a class="notification__container unread" href="${e.url}">          
                              <div class="notifications_details">
                                <strong>${e.sender}</strong>            
                                <small>${e.description}</small>
                                <strong>${e.chat_name !== undefined ? e.chat_name : ''}</strong>          
                              </div>
                              <div class="circle-35">${avatar}</div>
                            </a>`);
                    }
                    if ($('.messages__list').length) {
                        if (
                            ((e.sender_id !== undefined) && ($('.message-form #recipient').val() == e.sender_id || $('.chat-form #chat_id').val() == e.sender_id))
                            ||
                            (e.business_chat !== undefined && $('.business-message-form #business_chat_id').val() == e.chat_id)
                        ) {
                            $('.messages__list').prepend(`
                                <li class="d-flex justify-content-between">
                                    <a href="${e.sender_link}" class="circle-24">${avatar}</a>
                                    <div class="message__text">
                                        <div class="message__text--container" data-id="${e.specialID}">${e.content}</div><br />
                                        <div class="font-10">${e.time}</div>
                                    </div>
                                </li>
                            `);
                        }
                    }
                    if ($('#participants').length) {

                        let unreadMessageConversation = null;
                        if (e.conversation_type === 'business') {
                            unreadMessageConversation = $(`[data-id="business_chat_${e.chat_id}"]`).first();
                        } else if (e.conversation_type === 'private') {
                            unreadMessageConversation = $(`[data-id="${e.sender_id}"]`).first();
                        }

                        if (unreadMessageConversation) {
                            if (!$($(unreadMessageConversation).find('.unread-mark')).length) {
                                $(unreadMessageConversation).find('.member__tools').first().append('<span class="unread-mark"></span>');
                                $(unreadMessageConversation).find('.member__name div').first().addClass('font-weight-bold');
                            }
                            $(unreadMessageConversation).detach().prependTo('#participants')
                        }
                    }
                })
                .listen('MessageEdit', (e) => {
                    if ($('.messages__list').length) {
                        let updatedMessageBox = $(`[data-id="${e.id}"`);
                        $(updatedMessageBox).text(e.message);
                        if (!($(updatedMessageBox).siblings('.message__edited').length)) {
                            $(updatedMessageBox).parent().append(`<div class="font-10 message__edited">${window.translations.general.conversations.edited}</div>`);
                        }
                    }
                })
                .listen('MessageDelete', (e) => {
                    if ($('.messages__list').length) {
                        let messageContainer = $(`[data-id="${e.id}"`).parents('.message__text').first();
                        messageContainer.replaceWith(`
                            <div class="message__text">
                                <div class="message__text--container">
                                    <i>${window.translations.general.conversations.deleted}</i>
                                </div>
                            </div>
                        `);
                    }
                });
        }
    },
    manageArchive: (action, conversation_id, conversationElement) => {
        let isBusiness_chat = conversation_id.toString().indexOf('business_chat') >= 0;
        const id = isBusiness_chat ? conversation_id.split('_')[2] : conversation_id;
        let url = '';

        if (action === notificationsPlugin.archiveActions.ADD) {
            url = '/notification/to-archive';
        } else if (action === notificationsPlugin.archiveActions.REMOVE) {
            url = '/notification/from-archive';
        }

        $.ajax({
            type: 'POST',
            url: url,
            data: {id: id, isBusinessChat: isBusiness_chat},
            success: function () {
                $(conversationElement).remove();
                window.location.reload();
            },
        });
    },
    initMessageMenu: () => {
        $(document).on('click', '.message__text .message__options-menu--opener', function (event) {
            notificationsPlugin.closeMessageMenu();

            const {clientX: mouseX, clientY: mouseY} = event;
            const contextMenu = $(this).siblings('.message__options-menu');

            contextMenu.css('top', mouseY + 'px');
            contextMenu.css('left', mouseX + 'px');
            contextMenu.addClass('active');
        });

        $('body').click(function (event) {
            if (!($(event.target.offsetParent).is('.message__options-menu'))) {
                notificationsPlugin.closeMessageMenu();
            }

            if (notificationsPlugin.isEditing && !($(event.target).is('.message-editing')) && !($(event.target).is('.message__options--save'))) {
                notificationsPlugin.stopMessageEdit();
            }
        });

        $(document).on('click', '.message-action', function () {
            const action = $(this).data('action');
            const messageBox = $(this).parents('.message__text').find('.message__text--container');

            switch (action) {
                case 'edit':
                    notificationsPlugin.initMessageEdit(messageBox);
                    break;
                case 'delete':
                    notificationsPlugin.deleteMessage(messageBox);
                    break;
            }

            notificationsPlugin.closeMessageMenu();
        });
    },
    closeMessageMenu: () => {
        $('.message__options-menu').removeClass('active');
    },
    initMessageEdit: (messageBox) => {
        let saveButton = '<i class="fas fa-check message__options--save"></i>';
        notificationsPlugin.messageBeforeEdit = $(messageBox).text();

        notificationsPlugin.isEditing = true;
        $(messageBox).attr('contenteditable', true);
        $(messageBox).addClass('message-editing');
        $(messageBox).focus();
        $(saveButton)
            .insertAfter(messageBox)
            .click(function () {
                notificationsPlugin.saveEditedMessage(messageBox)
            });
    },
    saveEditedMessage: (messageBox) => {
        const url = '/message/edit';
        let messageID = $(messageBox).data('id');
        let message = $(messageBox).text();

        $.ajax({
            type: 'POST',
            url: url,
            data: {id: messageID, message: message},
            success: function (result) {
                $(messageBox).parents('li').replaceWith(result);
            }
        });

        notificationsPlugin.stopMessageEdit();
    },
    stopMessageEdit: () => {
        let messageBox = $('.message-editing');

        $(messageBox).removeAttr('contenteditable');
        $(messageBox).removeClass('message-editing');
        $('.message__options--save').remove();
        $(messageBox).text(notificationsPlugin.messageBeforeEdit);

        notificationsPlugin.isEditing = false;
        notificationsPlugin.messageBeforeEdit = null;
    },
    deleteMessage: (messageBox) => {
        const url = '/message/delete';
        let messageID = $(messageBox).data('id');

        $.ajax({
            type: 'POST',
            url: url,
            data: {id: messageID},
            success: function (result) {
                $(messageBox).parents('li').replaceWith(result);
            }
        });
    }
};
module.exports = notificationsPlugin;
