const Cropper = require('cropperjs');

const profilePlugin = {
    key: 'profile',

    pluginBehavior: (andels, $) => {
        profilePlugin.attachImageHandlers();
        profilePlugin.storeProfile();
        profilePlugin.emailUpdate(andels);
        profilePlugin.setProfileImageTooltip();
        if ($('.phone.profile-phone').length) {
            profilePlugin.phoneMask($('.phone.profile-phone'));
        }


        $('.password__container input').focusin(function () {
            $('.password__container input').removeClass('is-invalid');
            $('.password__container .fa-eye').show();

        });
        $('.password__container .fa-eye').on('click', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).prev('[type="text"]').attr('type', 'password')
            } else {
                $(this).addClass('active');
                $(this).prev('[type="password"]').attr('type', 'text');
            }

        });

        $('#radiusAll').click(function () {
            if ($(this).is(':checked')) {
                $('.radius--container').addClass('hidden');
            } else {
                $('.radius--container').removeClass('hidden');
            }
        });
    },
    attachImageHandlers: () => {
        if ($('.cover__corporation').length && $('#dropzone-corporation').length) {
            const url = $('.cover__corporation').data('url');
            if (url) {
                andels.plugins.utils.attacheDropzone(".cover__corporation #dropzone-corporation",
                    profilePlugin.dropZoneOptions(url, '.cover__corporation'));
            }
        }

        if ($('.profile__image').length) {
            andels.plugins.utils.attacheDropzone(".profile__image #dropzone",
                profilePlugin.cropOptions());

            $('.change-avatar').on('click', function () {
                $('.profile__image #dropzone').click();
            });

        }
    },
    emailUpdate: (andels) => {
        $('#emailUpdate').click(function () {
            if ($('#email').val() == '') {
                $('#email').addClass('is-invalid');
            } else {
                andels.plugins.utils.attacheSpinner();
                $.ajax({
                    type: 'POST',
                    url: '/change-email',
                    data: {email: $('#email').val()},
                    success: function () {
                        window.location.reload();
                    },
                    error: function (data) {
                        $(`#${data.responseJSON.parent}`).addClass('is-invalid');
                        $(`.${data.responseJSON.parent}-error`).html(data.responseJSON.html).show();
                        andels.plugins.utils.removeSpinner();
                    },
                });
            }
        });
    },
    phoneMask: (element) => {
        if (element.val().length) {
            element.prev().show();
            element.css('padding', '0.375rem 0.75rem 0.375rem 2.7rem');
        } else {
            element.prev().hide();
            element.css('padding', '0.375rem 0.75rem');
        }
    },
    storeProfile: () => {
        $('.phone').mask('00000000');
        $('.phone.profile-phone').on('keyup', function () {
            profilePlugin.phoneMask($(this));
        });
        $('#submitProfile').on('click', function () {
            let phone = $('.phone').val();
            if (phone.length == 8 || phone.length == 0) {
                $(this).parents('form').submit();
            } else {
                $('.phone').addClass('is-invalid');
            }
        });
    },
    setProfileImageTooltip: () => {
        if ($('.profile__image--tooltip').length) {
            $('.profile__image--tooltip-close').on('click', function () {
                $(this).parent().remove();
                document.cookie = "profile_photo_tooltip=false; path=/; max-age=864000"
            });
        }
    },
    cropOptions: () => {
        return {
            url: `/profile/upload`,
            maxFilesize: 100,
            method: 'POST',
            parallelUploads: 1,
            acceptedFiles: ".jpeg,.jpg,.png",
            uploadMultiple: false,
            paramName: 'avatar',
            timeout: 60000,
            autoProcessQueue: true,
            init: function () {
                this.on('sending', function (file, xhr, formData) {
                    formData.append("_token", $('[name="_token"]').val());
                });
                this.on('addedfile', function () {
                    $('.alert.alert-danger').remove();
                });
            },
            success: function (file, response) {
                if ($(`.profile__image .user-initials`).length > 0) {
                    let img_container = $(`.profile__image .user-initials`).parent();
                    $(`.profile__image .user-initials`).remove();
                    img_container.append(`<img src="${response}"/>`)
                }

                $(`.profile__image img`).attr('src', response);
            },
            error: function (file, response) {
                if (response.message) {
                    response = response.message;
                }
                $(`.profile__image [id^=dropzone]`).prepend(`<div class="alert alert-danger">${response}</div>`);
            },
            transformFile: function (file, done) {
                let editorBox = andels.plugins.utils.getCropperBox('');
                let image = new Image();
                image.src = URL.createObjectURL(file);
                editorBox.editor.appendChild(image);

                let cropper = new Cropper(image, {aspectRatio: 1});
                editorBox.button.addEventListener('click', function () {
                    let canvas = cropper.getCroppedCanvas({
                        width: 256,
                        height: 256
                    });
                    canvas.toBlob(function (blob) {
                        done(blob);
                    });
                    editorBox.editor.remove();
                });
            }
        };
    },
    dropZoneOptions: (url, imageClass) => {
        return {
            url: `/${url}`,
            maxFilesize: 100,
            method: 'POST',
            parallelUploads: 1,
            acceptedFiles: ".jpeg,.jpg,.png",
            uploadMultiple: false,
            paramName: 'avatar',
            timeout: 60000,
            autoProcessQueue: true,
            init: function () {
                this.on('sending', function (file, xhr, formData) {
                    formData.append("_token", $('[name="_token"]').val());
                });
                this.on('addedfile', function () {
                    $('.alert.alert-danger').remove();
                });
            },
            success: function (file, response) {
                $(`${imageClass} img`).attr('src', response);
            },
            error: function (file, response) {
                if (response.message) {
                    response = response.message;
                }
                $(`${imageClass} [id^=dropzone]`).prepend(`<div class="alert alert-danger">${response}</div>`);
            }
        };
    },
};
module.exports = profilePlugin;
