const invitePlugin = {
    key: 'invite',

    pluginBehavior: (andels, $) => {

        $('.modal-content').on('click', () => {
            $('#corporation__results--placeholder').hide();
            $('#user__results--placeholder').hide();
        });

        $("#invite_members_search").on('keyup', function () {
            if ($(this).val().length > 3) {
                invitePlugin.ajaxCall($(this), $('#corporation__results--placeholder'), 'corporation', '/api/invite');
            }
        });
        $("#invite_users_search").on('keyup', function () {
            if ($(this).val().length > 3) {
                invitePlugin.ajaxCall($(this), $('#user__results--placeholder'), 'user', '/api/invite/user');
            }
        });

        invitePlugin.sendInvitations();
        invitePlugin.initRemoveInvited();
        invitePlugin.attachInviteTypeSwitch();
        invitePlugin.attachInviteBMHandlers();
    },
    sendInvitations: () => {
        $('#sendInvitation').on('click', function () {
            if ($('input[name^="invite"]').length) {
                $('#sendInvitation').hide();
                andels.plugins.utils.attacheSpinner();
                $.ajax({
                    type: 'POST',
                    url: '/invite/members',
                    data: $('#invitationForm').serialize(),
                    success: function () {
                        andels.plugins.utils.trackUmamiEvent('Send an invitation')

                        window.location.reload();
                    },
                    error: function (data) {
                        $(`#search__results--placeholder`).html('');
                        $('#search__results--placeholder').prepend(`<div class="alert alert-warning">${data.responseJSON.error}</div>`);
                        $('#sendInvitation').show();
                    },
                    complete: function () {
                        andels.plugins.utils.removeSpinner();
                    }
                });
            } else {
                $('#search__results--placeholder').prepend(`<div class="alert alert-warning">${window.translator.trans('form.choose_member')}</div>`);
            }
        });
    },
    ajaxCall: (query, element, type, url) => {
        const queryValue = query.val();
        let data = $('#invitationForm').serializeArray();
        data.push({name: "query", value: queryValue});

        $.ajax({
            type: 'GET',
            url: url,
            data: data,
            success: function (data) {
                element.html('');
                if (data.data) {
                    $.each(data.data, (key, value) => {
                        element.append(invitePlugin.preTemplate(key, value, type, data.invited));
                    });
                    invitePlugin.addToResults();
                } else {
                    if(data.newUserInvited !== undefined && data.newUserInvited) {
                        element.append(`<div class="mb-1">${window.translator.trans('form.search_invite_new_member_invited', {member: queryValue})}</div>`);
                    } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(queryValue)) {
                        element.append(`<div class="confirm_new_user_search cursorPointer mb-1" data-value="${queryValue}">${window.translator.trans('form.search_invite_new_member', {member: queryValue})}</div>`);
                        invitePlugin.addToResults();
                    } else {
                        element.append(`<div class="mb-1">${window.translator.trans('form.search_user_not_found')}</div>`);
                    }
                }

                element.show();
            },
            error: function (data) {
                $('#search__results--placeholder').prepend(`<div class="alert alert-warning">${data.responseJSON.data}</div>`);
            },
        });
    },
    clearPlaceholder: (placeholder) => {
        $(`#${placeholder}__results--placeholder`).html('');
        $(`#${placeholder}__results--placeholder`).hide();
    },
    template: (key, value, type) => {
        if ($(`[name="invite_${type}[${key}]"]`).length === 0) {
            const adminCheckbox = type === 'user' ? `<input type="checkbox" class="form-check-input styled-checkbox" id="admin-check-${key}" name="invite_user[${key}][admin]"><label class="form-check-label" for="admin-check-${key}">Admin</label>` : '';

            return `<div class="form-group form-check mb-0 d-flex align-items-center">
                        <i class="fas fa-times remove-invited"></i>
                        <input type="hidden" name="invite_${type}[${key}][value]" value="1">
                        <span class="mx-4 bold">${value}</span>
                        ${adminCheckbox}
                    </div>`;
        } else {
            return '';
        }
    },
    newUserTemplate: (value) => {
        if ($(`[value="${value}"]`).length === 0) {
            return `<div class="form-group form-check mb-0 d-flex align-items-center">
                    <i class="fas fa-times remove-invited"></i>
                    <input type="hidden" name="invite_new_nabonet_user[${value}][value]" value="${value}">
                    <span class="mx-4 bold">${value}</span>
                    <input type="checkbox" class="form-check-input styled-checkbox" id="admin-check-${value}" name="invite_new_nabonet_user[${value}][admin]"><label class="form-check-label" for="admin-check-${value}">Admin</label>
                </div>`;
        } else {
            return '';
        }
    },
    preTemplate: (key, value, type, invited) => {
        let notice = '';
        if ($.inArray(key, invited) != -1) {
            notice = '<div class="font-12 text-danger">You have already sent an invitation to this recipient!</div>';
        }
        return `<div class="confirm__search cursorPointer mb-1" data-type="${type}" data-id="${key}">${value}</div>${notice}`;
    },
    addToResults: () => {
        $('#inviteMembers .confirm__search').on('click', function () {
            $('#search__results--placeholder').append(invitePlugin.template($(this).data('id'), $(this).html(), $(this).data('type')));

            $('#invite_users_search').val('');
            $('#invite_members_search').val('');
            invitePlugin.clearPlaceholder('user');
            invitePlugin.clearPlaceholder('corporation');
        });
        $('#inviteMembers .confirm_new_user_search').on('click', function () {
            $('#search__results--placeholder').append(invitePlugin.newUserTemplate($(this).data('value')));

            $('#invite_users_search').val('');
            $('#invite_members_search').val('');
            invitePlugin.clearPlaceholder('user');
            invitePlugin.clearPlaceholder('corporation');
        });
    },
    initRemoveInvited: () => {
        $(document).on('click', '.remove-invited', function () {
            $(this).parent().remove();
        });
    },
    attachInviteTypeSwitch: () => {
        $('[name="invite_type"]').on('change', function () {
            const activeType = $(this).val();
            const activeInput = $(`#invitation__container--${activeType}`);

            if (activeInput.length) {
                $('#search__results--placeholder').html('');
                $('.invitation__container').hide();
                activeInput.show();
            }
        });
    },
    attachInviteBMHandlers: () => {
        if($('#bmInvite').length) {
            $(document).on('change', '.invite-bm-check', function () {
                const checked = $(this).is(':checked');
                const id = $(this).attr('id').split('_')[3];
                const textfield = $(`[id="invite_bm_desc_${id}"]`);

                if(checked) {
                    textfield.prop('disabled', false);
                    textfield.focus();
                } else {
                    textfield.prop('disabled', true);
                }
            });
        }
    }
};
module.exports = invitePlugin;
