const systemNotificationPlugin = {
    key: 'system_notification',

    pluginBehavior: (andels, $) => {
        if ($('#description_c').length) {
            let createEditor;
            window.attachSimpleEditor($('#description_c')[0])
                .then(editor => {
                    createEditor = editor;
                    systemNotificationPlugin.attacheCreate(createEditor);
                    window.andels.plugins.utils.linkPreviewStyles();
                });

        }

        $('.system-update').on('click', function () {
            const id = $(this).data('id');
            $.ajax({
                type: 'GET',
                url: '/board/notification/' + id,
                data: {corporation: $('#notificationCreate #corporation').val()},
                success: function (data) {
                    $('#updateNotificationForm .modal-body').html(data);
                    let updateEditor;
                    window.attachSimpleEditor($('#updateNotificationForm #description_update')[0])
                        .then(editor => {
                            updateEditor = editor;
                            systemNotificationPlugin.attacheUpdate(updateEditor);
                            window.andels.plugins.utils.linkPreviewStyles()
                        });
                    andels.plugins.utils.datetimePicker('from_u', 'to_u');
                    $('#updateNotificationForm').modal('show');
                },
                error: function () {
                    window.location.reload();
                },
            });

        });

    },
    attacheCreate: (createEditor) => {
        $('.notification-create').on('click', function () {
            if (systemNotificationPlugin.formIsValid(createEditor, '#notificationCreate')) {
                $(`#notificationCreate`).submit();
            }
        });
        $('#createNotificationForm').on('hide.bs.modal', function () {
            createEditor.setData('');
            $('#notificationCreate #from').val('');
            $('#notificationCreate #to').val('');
            $('.is-invalid').removeClass('is-invalid');
            $(`.ck.ck-editor__main`).css('border', '1px solid #c4c4c4');
        });
    },
    attacheUpdate: (updateEditor) => {
        $('.notification-send-update').on('click', function () {
            if (systemNotificationPlugin.formIsValid(updateEditor, '#notificationUpdate')) {
                $(`#notificationUpdate`).submit();
            }
        });
        $('#updateNotificationForm').on('hide.bs.modal', function () {
            $('#updateNotificationForm .modal-body').html('');
        });
    },

    formIsValid: (editor, form) => {
        let valid = true;
        if (!editor.getData()) {
            $(`${form} .ck.ck-editor__main`).css('border', '1px solid #E26161');
            valid = false;
        }
        if (!$(`${form} [name="starts_at"]`).val()) {
            $(`${form} [name="starts_at"]`).addClass('is-invalid');
            valid = false;
        }
        if (!$(`${form} [name="ends_at"]`).val()) {
            $(`${form} [name="ends_at"]`).addClass('is-invalid');
            valid = false;
        }
        return valid;
    }
};
module.exports = systemNotificationPlugin;
