const Cropper = require("cropperjs");

const commonPlugin = {
    key: 'common',

    pluginBehavior: (andels, $) => {
        $('#radius').slider();
        $('#radius').on("slide", function (slideEvt) {
            $("#radiusSliderVal").text(slideEvt.value);
        });

        $('.btn-collapse').on('click', function () {
            const id = $(this).data('target');
            if ($(`#${id}`).hasClass('hide--mobile')) {
                $(`#${id}`).removeClass('hide--mobile');
            } else {
                $(`#${id}`).addClass('hide--mobile');
            }
        });

        $('label[for="policy"]').on('click', function () {
            if ($('.btn-submit').attr('disabled') == 'disabled') {
                $('.btn-submit').removeAttr('disabled');
                $('.btn-submit').removeProp('disabled');
            } else {
                $('.btn-submit').attr('disabled', 'disabled');
                $('.btn-submit').prop('disabled', 'disabled');
            }
        });

        andels.plugins.utils.datetimePicker();
        andels.plugins.utils.singleDatePicker();

        commonPlugin.selectAll();
        if (document.getElementById('sortable')) {
            commonPlugin.sortableInit(document.getElementById('sortable'));
        }
        $('.cvr').mask('#');
        $('.ean').mask('0000000000000');
        $('.vat').mask('00000000')
        $('.price').keypress(function (event) {
            if (event.which < 48 || event.which > 57) {
                if (event.which != 32 && event.which != 46 && event.which != 44) {
                    event.preventDefault();
                }
            }
        });

        $('#personalEmail').on('click', function () {
            if (!$(this).is(':checked')) {
                $('[name="personal_frequency"]').prop('checked', false);
            } else {
                $('[name="personal_frequency"][value="1"]').prop('checked', 'checked');
            }
        });
        $('[name="personal_frequency"]').on('click', function () {
            $('#personalEmail').prop('checked', 'checked');
        });

        $('.reported-content').popover({
            trigger: 'focus'
        });

        $('.modal form').on('keyup keypress', function (e) {
            let keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
        });

        $("#show_corporation").on('keyup', function () {
            if ($(this).val().length > 3) {
                $.ajax(
                    {
                        type: 'GET',
                        url: '/api/livesearch',
                        data: {query: $(this).val()},
                        success: function (data) {
                            $('#welcome-search-results--placeholder').html(data.html);
                            $('#welcome-search-results--placeholder').show();
                        },
                        error: function (data) {
                            $('#welcome-search-results--placeholder').prepend(`<div class="alert alert-warning">${data.responseJSON.data}</div>`);
                        },
                    });
            }
        });

        function organizationCvr() {
            if ($('#profitable').is(':checked')) {
                $('#cvr').attr('required', 'required');
                $('[for="cvr"]').append('<span class="text-danger" id="cvr_label">*</span>');
            } else {
                $('#cvr').removeAttr('required', 'required');
                $('#cvr_label').remove();
            }
        }

        if ($('#profitable').length && ($('#cvr').length)) {
            $('#profitable').on('click', function () {
                organizationCvr();
            });
            organizationCvr();
        }

        if ($('#fullEditor').length) {
            window.attachFullEditor($('#fullEditor')[0]);

            /*fullEditor.codemirror.on('keyup', function () {
              if (!fullEditor.value()) {
                $('#check_cvr').hide();
              } else {
                $('#check_cvr').show();
              }
            });*/
        }

        $('#name').on('keyup', function () {
            if (!$(this).val()) {
                $('#check_cvr').hide();
            } else {
                $('#check_cvr').show();
            }
        });

        if ($('.small__editor').length) {
            window.attachSimpleEditor($(`.small__editor`)[0]).then(editor => {
                window.andels.plugins.utils.linkPreviewStyles()
            });
        }

        $('#check_cvr').on('click', commonPlugin.validateForm);

        if ($('#gallery_form').length) {
            andels.plugins.utils.attacheDropzone("#gallery_form #dropzone",
                commonPlugin.dropZoneGalleryOptions($('#gallery_form').data('action')));
        }
        commonPlugin.removeMembers(andels);
        commonPlugin.setAdmin(andels);
        commonPlugin.cancelNotification();
        if ($('#organizationUpload').length) {
            commonPlugin.organizationFiles(andels);
        }

        window.andels.plugins.utils.linkPreviewStyles();
        commonPlugin.setDashboardHight();
        commonPlugin.attachDashboardScroll();
        commonPlugin.attachImageHandlers();
        commonPlugin.initCommonAjaxPagination();
    },
    organizationFiles: (andels) => {
        const url = $('#organizationUpload').data('action');
        andels.plugins.utils.attacheDropzone(
            "#organizationUpload #dropzone",
            andels.plugins.utils.filesDropzone(url, $('#organizationUpload'), true)
        );
    },
    cancelNotification: () => {
        $('.system-notification .cancel').on('click', function () {
            const element = $(this).parent('.system');
            $.ajax(
                {
                    type: 'POST',
                    url: '/system/viewed',
                    data: {id: element.data('id')},
                    success: function () {
                        element.remove();
                    },
                    error: function (data) {
                        $('#welcome-search-results--placeholder').prepend(`<div class="alert alert-warning">${data.responseJSON.data}</div>`);
                    },
                });
        });
    },

    removeMembers: (andels) => {
        $('.remove-member').on('click', function () {
            const id = $(this).parent('li').data('id');
            const owner = $(this).parents('ul').data('owner');
            const main_type = $(this).parents('ul').data('type');
            const type = $(this).parent('li').data('type');
            andels.plugins.utils.attacheSpinner();
            $.ajax({
                type: 'POST',
                url: '/remove/from',
                data: {owner: owner, id: id, type: type, main_type: main_type},
                success: function () {
                    $(`li[data-id=${id}]`).remove();
                    andels.plugins.utils.removeSpinner();
                },
                error: function (data) {
                    $(`ul[data-id=${owner}]`).prepend(data);
                    andels.plugins.utils.removeSpinner();
                },
            });
        });
    },
    selectAll: () => {
        $('#selectAll').on('click', function () {
            if ($(this).is(':checked')) {
                $(`#${$(this).data('toggle')}`).find('[type="checkbox"]').attr('checked', 'checked');
                $(`#${$(this).data('toggle')}`).find('[type="checkbox"]').prop('checked', 'checked');
            } else {
                $(`#${$(this).data('toggle')}`).find('[type="checkbox"]').removeAttr('checked');
                $(`#${$(this).data('toggle')}`).find('[type="checkbox"]').prop('checked', false);
            }
        });
    },
    setAdmin: (andels) => {
        $('.set-admin').on('click', function () {
            const element = $(this);
            const id = $(this).parents('li').data('id');
            const owner = $(this).parents('ul').data('owner');
            const main_type = $(this).parents('ul').data('type');
            andels.plugins.utils.attacheSpinner();
            let set = 0;
            if ($(this).is(':checked')) {
                set = 1;
            }
            $.ajax({
                type: 'POST',
                url: '/set/admin',
                data: {owner: owner, id: id, set: set, main_type: main_type},
                success: function () {
                    andels.plugins.utils.removeSpinner();
                },
                error: function (data) {
                    if (set) {
                        element.prop('checked', '');
                    } else {
                        element.prop('checked', 'checked');
                    }
                    $(`ul[data-id=${group}]`).prepend(data);
                    andels.plugins.utils.removeSpinner();
                },
            });
        });
    },
    sortableInit: (el) => {
        return window.sortable.Sortable.create(el, {
            animation: 150,
            ghostClass: 'ghost-class',
            group: "sortable",
            sort: true,
            delay: 0,
            delayOnTouchOnly: false,
            touchStartThreshold: 0,
            store: null,
            easing: "cubic-bezier(1, 0, 0, 1)",
            draggable: ".sortable-item",
            handle: '.handle',
            onSort: function () {
                const order = this.toArray();
                $.each(order, function (index, id) {
                    $(`.sortable-item[data-id="${id}"] .order`).val(5 - index);
                });
            }
        });
    },
    validateForm: () => {
        function validateAddressRemove() {
            if ($('input[id^=remove]').length) {
                $('.dawa-address.removed').each(function () {
                    $('#confirmAddressDelete #address').append($(this).find('label').html());
                });
                $('#confirmAddressDelete').modal('show');
            } else {
                $('#corporation_edit').submit();
            }
        }

        const id = $('#cvr').data('id');
        if ($('#cvr').val()) {
            let result = new Promise(function (resolve, reject) {
                $.ajax({
                    type: 'POST',
                    url: '/api/check/cvr',
                    data: {cvr: $('#cvr').val(), id: id, type: 'corporation'},
                    success: function () {
                        $('#cvr').removeClass('is-invalid');
                        resolve();
                    },
                    error: function (data) {
                        $('#cvr').siblings('.invalid-feedback').html(data.error);
                        reject();
                    },
                });
            });

            result.then(() => {
                validateAddressRemove();
                $('#confirmAddressDelete #submitForm').on('click', () => {
                    $('#corporation_edit').submit();
                });
            }).catch(() => {
                $('#cvr').addClass('is-invalid');
            });
        } else {
            validateAddressRemove();
            $('#confirmAddressDelete #submitForm').on('click', () => {
                $('#corporation_edit').submit();
            });
        }
    },
    dropZoneGalleryOptions: (url) => {
        return {
            url: url,
            maxFilesize: 100,
            parallelUploads: 20,
            acceptedFiles: ".jpeg,.jpg,.png,.gif,.mp4,.3gpp,.3gp,.3gpp2",
            uploadMultiple: true,
            paramName: 'images',
            timeout: 60000,
            init: function () {
                this.on('sending', function (file, xhr, formData) {
                    let data = $('#gallery_form').serializeArray();
                    $.each(data, function (key, el) {
                        formData.append(el.name, el.value);
                    });
                });
            },
            success: function (file, response) {
                $('#uploads--placeholder').html(response.html);
                commonPlugin.selectAll();
            },
            error: function (file, response) {
                let error = window.translator.trans('errors.smth_went_wrong');
                if (response.error) {
                    error = response.error;
                } else if (response.message) {
                    error = response.message;
                }
                $('#dropzone').prepend(`<div class="alert alert-danger">${error}</div>`);
                return false;
            }
        }
    },
    setDashboardHight: () => {
        if ($('.home-container').length) {
            const sidebarHeight = $('.home-container').find('.sidebar-block__right').first().height();
            $('.list-preloader').remove();
            $('.dashboard-container').height(sidebarHeight);
        }
    },
    attachDashboardScroll: () => {
        $('.dashboard-container').on('scroll', function () {
            const part = $(this).data('part');
            const container = $(this);

            if (this.scrollTop + this.offsetHeight >= this.scrollHeight) {

                $.ajax({
                    type: 'POST',
                    url: '/homefeed',
                    data: {part: part},
                    success: function (data) {
                        if (data.cnt > 0) {
                            container.append(data.html);
                            $.each($(`.iteration-${part} [id^="comment_create-"]`), function () {
                                window.attachEnteredSimpleEditor($(this).find('textarea').first());
                            });
                            $('.postgallery').each(function (index, value) {
                                attachLightGallery(value);
                            });
                            container.data('part', part + 1);
                        }
                    }
                });
            }
        });
    },
    attachImageHandlers: () => {
        if ($('.cover__image').length) {
            const url = $('.cover__image').data('url');
            if (url) {
                if ($('.cover__image').hasClass('cover__image--complex')) {
                    andels.plugins.utils.attacheDropzone("#dropzone_complex",
                        commonPlugin.cropTwoImagesMainDropzoneOptions(url, '.cover__image'));
                } else {
                    andels.plugins.utils.attacheDropzone(".cover__image #dropzone",
                        commonPlugin.dropZoneOptions(url, '.cover__image'));
                }
            }
        }

        if ($('.complex-image-field #topImage') && $('.complex-image-field #imageSmall')) {
            $(document).on('change', '.complex-image-field #topImage', function () {
                if (this.files.length) {
                    const file = this.files[0];
                    const mainImageField = this;
                    const smallImageField = $('#imageSmall').get(0);

                    let editorBox = andels.plugins.utils.getCropperBox(window.translator.trans('form.cropper.crop_top_image'));
                    let image = new Image();
                    image.src = URL.createObjectURL(file);
                    editorBox.editor.appendChild(image);

                    let cropper = new Cropper(image, {aspectRatio: 5 / 2,});

                    editorBox.button.addEventListener('click', function () {
                        let canvas = cropper.getCroppedCanvas();

                        canvas.toBlob(function (blob) {
                            const croppedFile = new File([blob], file.name, {type: blob.type});
                            const fileList = new DataTransfer();
                            fileList.items.add(croppedFile);
                            mainImageField.files = fileList.files;
                        });
                        editorBox.editor.remove();

                        let editorBox2 = andels.plugins.utils.getCropperBox(window.translator.trans('form.cropper.crop_avatar'));
                        let image2 = new Image();
                        image2.src = URL.createObjectURL(file);
                        editorBox2.editor.appendChild(image2);

                        let cropper2 = new Cropper(image2, {aspectRatio: 4 / 3,});

                        editorBox2.button.addEventListener('click', function () {
                            let canvas2 = cropper2.getCroppedCanvas({
                                width: 200,
                                height: 150
                            });

                            canvas2.toBlob(function (blob) {
                                const croppedFile = new File([blob], file.name, {type: blob.type});
                                const fileList = new DataTransfer();
                                fileList.items.add(croppedFile);
                                smallImageField.files = fileList.files;
                            });

                            editorBox2.editor.remove();
                        });
                    });
                }

            });
        }
    },
    cropTwoImagesMainDropzoneOptions: (url, imageClass) => {
        let originalFile = null;
        return {
            url: `/${url}`,
            maxFilesize: 100,
            method: 'POST',
            parallelUploads: 1,
            acceptedFiles: ".jpeg,.jpg,.png",
            uploadMultiple: false,
            paramName: 'avatar',
            timeout: 60000,
            autoProcessQueue: true,
            init: function () {
                this.on('sending', function (file, xhr, formData) {
                    formData.append("_token", $('[name="_token"]').val());
                });
                this.on('addedfile', function (file) {
                    originalFile = new File([file], file.name, {type: file.type});
                    $('.alert.alert-danger').remove();
                });
            },
            success: function (file, response) {
                $(`${imageClass} img`).attr('src', response);
                andels.plugins.utils.attacheDropzone("#part_dropzone_complex",
                    commonPlugin.cropTwoImagesAdditionalDropzoneOptions(url, imageClass)).addFile(originalFile);
            },
            error: function (file, response) {
                if (response.message) {
                    response = response.message;
                }
                $(`${imageClass} [id^=dropzone]`).prepend(`<div class="alert alert-danger">${response}</div>`);
            },
            transformFile: function (file, done) {
                let editorBox = andels.plugins.utils.getCropperBox(window.translator.trans('form.cropper.crop_top_image'));
                let image = new Image();
                image.src = URL.createObjectURL(file);
                editorBox.editor.appendChild(image);

                let cropper = new Cropper(image, {aspectRatio: 5 / 2});
                editorBox.button.addEventListener('click', function () {
                    let canvas = cropper.getCroppedCanvas();
                    canvas.toBlob(function (blob) {
                        done(blob);
                    });
                    editorBox.editor.remove();
                });
            }
        };
    },
    cropTwoImagesAdditionalDropzoneOptions: (url, imageClass) => {
        return {
            url: `/${url}`,
            maxFilesize: 100,
            method: 'POST',
            parallelUploads: 1,
            acceptedFiles: ".jpeg,.jpg,.png",
            uploadMultiple: false,
            paramName: 'small_avatar',
            timeout: 60000,
            autoProcessQueue: true,
            init: function () {
                this.on('sending', function (file, xhr, formData) {
                    formData.append("_token", $('[name="_token"]').val());
                });
            },
            success: function (file, response) {
                $(`${imageClass} img`).attr('src', response);
                this.destroy();
            },
            error: function (file, response) {
                if (response.message) {
                    response = response.message;
                }
                $(`[id^=dropzone]`).prepend(`<div class="alert alert-danger">${response}</div>`);
            },
            transformFile: function (file, done) {
                let editorBox = andels.plugins.utils.getCropperBox(window.translator.trans('form.cropper.crop_avatar'));
                let image = new Image();
                image.src = URL.createObjectURL(file);
                editorBox.editor.appendChild(image);

                let cropper = new Cropper(image, {aspectRatio: 4 / 3});
                editorBox.button.addEventListener('click', function () {
                    let canvas = cropper.getCroppedCanvas({
                        width: 200,
                        height: 150
                    });
                    canvas.toBlob(function (blob) {
                        done(blob);
                    });
                    editorBox.editor.remove();
                });
            }
        };
    },
    initCommonAjaxPagination: () => {
        if ($('#eventsList').length) {
            $(window).on('scroll.list', function () {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    andels.plugins.utils.loadNextPageItems('/more/events', $('#itemsList'));
                }
            });
        }

        if ($('#pagesList').length) {
            $(window).on('scroll.list', function () {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    andels.plugins.utils.loadNextPageItems('/more/pages', $('#itemsList'));
                }
            });
        }
    },
};
module.exports = commonPlugin;
