const pantherPlugin = {
  key: 'panther',

  pluginBehavior: (andels, $) => {
    pantherPlugin.pantherProduct();
    pantherPlugin.pantherIntegration();
    pantherPlugin.pantherCheckbox();
    $('.profile-phone').on('change', function() {
      pantherPlugin.pantherCheckbox();
    });
  },
  pantherCheckbox: () => {
    if($('.profile-phone').val()) {
      $('.sms--checkbox').removeClass('hidden');
    } else {
      $('.sms--checkbox').addClass('hidden');
    }
  },
  pantherIntegration: () => {
    $('#panther_new').on('click', function() {
      let phone = $('.phone').val();
      if ($('#name').val() == '') {
        $('#name').addClass('is-invalid');
      } else if ($('#product_id').val() == '') {
        $('.panther__error').removeClass('hidden');
      } else if (phone.length != 8 && phone.length != 0) {
        $('.phone').addClass('is-invalid');
      } else {
        $(this).parents('form').submit();
      }
    });
  },
  pantherProduct: () => {
    $('.product__single').on('click', function () {
      $('.product__single').removeClass('chosen-product');
      if ($('#product_id').length) {
        $('#product_id').val($(this).data('id'));
        $(this).addClass('chosen-product');
        $('.panther__error').addClass('hidden');
      }
    });
    $('.product__multi').on('click', function () {
      if ($(this).hasClass('chosen-product')) {
        $(this).removeClass('chosen-product');
      } else {
        $(this).addClass('chosen-product');
      }
      let result = '';
      $.each($('.product__multi.chosen-product'), function () {
        result += `${$(this).data('id')},`;
      });
      $('#product_multi').val(result);
    });
  },
};
module.exports = pantherPlugin;
