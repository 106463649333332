window.attachSimpleEditor = function (element) {

    return ClassicEditor
        .create(element, {
            removePlugins: ['Heading', 'RestrictedEditingMode', 'HtmlEmbed', 'Title', 'ImageToolbar', 'ImageResize'],
            toolbar: [
                'bold', 'italic', '|', 'bulletedList', 'numberedList', 'blockQuote', '|', 'Link'
            ],
            mediaEmbed: {
                previewsInData: true
            },
            htmlSupport: {
                allow: [
                    {
                        name: /^(div|p|span|img)$/,
                        classes: ['link-preview__title', 'link-preview__description', 'link-preview__img'],
                        attributes: true
                    }
                ]
            },
            link: {
                defaultProtocol: 'https://'
            }
        })
        .catch(error => {
            console.error(error);
        });

};
window.attachFullEditor = function (element) {
    return ClassicEditor
        .create(element, {
            removePlugins: ['RestrictedEditingMode', 'HtmlEmbed', 'Title', 'BlockImage', 'ImageToolbar', 'ImageResize'],
            toolbar: ['heading', '|',
                'bold', 'italic', 'Link', 'bulletedList', 'numberedList', '|',
                'blockQuote', 'InsertTable', 'MediaEmbed', '|', "Indent", 'Outdent', '|', 'Undo', 'Redo'],
            mediaEmbed: {
                previewsInData: true
            },
            htmlSupport: {
                allow: [
                    {
                        name: /^(div|p|span|img)$/,
                        classes: ['link-preview__title', 'link-preview__description', 'link-preview__img', 'image-inline'],
                        attributes: true
                    }
                ]
            },
            link: {
                defaultProtocol: 'https://'
            },
        })
        .then(editor => {
            window.andels.plugins.utils.linkPreviewStyles()
        })
        .catch(error => {
            console.error(error);
        });
};

window.attachEnteredSimpleEditor = function (element) {
    let prevKeyCode = 0;

    function getFeedItems(queryText) {
        return new Promise(function (resolve, reject) {
            $.ajax({
                type: 'GET',
                url: '/api/invite/user',
                data: {query: queryText},
                success: function (data) {
                    if (data.data) {
                        let searchResults = Array();
                        $.each(data.data, (key, value) => {
                            searchResults.push({
                                id: `@${value}`,
                                userId: key,
                                name: value,
                                link: `/user/${key}-${encodeURIComponent(value)}`
                            });
                        });
                        resolve(searchResults);
                    } else {
                        reject([]);
                    }
                },
                error: function () {
                    reject([]);
                },
            });
        });
    }

    function customItemRenderer(item) {
        const itemElement = document.createElement('span');
        itemElement.id = `mention-list-item-id-${item.userId}`;
        itemElement.textContent = `${item.name} `;
        return itemElement;
    }

    ClassicEditor
        .create(element[0], {
            removePlugins: ['Heading', 'RestrictedEditingMode', 'HtmlEmbed', 'Title', 'ImageToolbar', 'ImageResize'],
            extraPlugins: [MentionLinks],
            toolbar: ['bold', 'italic', 'Link'],
            mention: {
                feeds: [
                    {
                        marker: '@',
                        feed: getFeedItems,
                        itemRenderer: customItemRenderer,
                        minimumCharacters: 4
                    }
                ]
            },
            mediaEmbed: {
                previewsInData: true
            },
            htmlSupport: {
                allow: [
                    {
                        name: /^(div|p|span|img)$/,
                        classes: ['link-preview__title', 'link-preview__description', 'link-preview__img'],
                        attributes: true
                    }
                ]
            },
            link: {
                defaultProtocol: 'https://'
            }
        })
        .then(editor => {
            element.parents('form').first().on('submit', function () {
                editor.updateSourceElement();
            });
            editor.editing.view.document.on('keydown', (evt, data) => {
                if (data.keyCode != 13) {
                    prevKeyCode = (data.keyCode == 16) ? 16 : 0;
                }
                if (data.keyCode == 13 && prevKeyCode != 16) {
                    //data.domTarget.lastChild.remove();
                    if (data.domTarget.innerText.replace(/(\r\n|\n|\r)/gm, "").length > 0 || data.domTarget.innerHTML.indexOf('<iframe') >= 0) {
                        element.parents('form').submit();
                        data.preventDefault();
                        evt.stop();
                    } else {
                        element.parents('form').find('textarea').addClass('is-invalid');
                    }
                }
                window.andels.plugins.utils.linkPreviewStyles()
            });
        })
        .catch(error => {
            console.error(error);
        });

    /*
   * This plugin customizes the way mentions are handled in the editor model and data.
   * Instead of a classic <span class="mention"></span>,
   */
    function MentionLinks(editor) {
        editor.conversion.for('upcast').elementToAttribute({
            view: {
                name: 'a',
                key: 'data-mention',
                classes: 'mention',
                attributes: {
                    href: true,
                    'data-user-id': true
                }
            },
            model: {
                key: 'mention',
                value: viewItem => editor.plugins.get('Mention').toMentionAttribute(viewItem)
            },
            converterPriority: 'high'
        });

        editor.conversion.for('downcast').attributeToElement({
            model: 'mention',
            view: (modelAttributeValue, {writer}) => {
                // Do not convert empty attributes (lack of value means no mention).
                if (!modelAttributeValue) {
                    return;
                }

                return writer.createAttributeElement('a', {
                    class: 'mention',
                    'data-mention': modelAttributeValue.id,
                    'data-user-id': modelAttributeValue.userId,
                    'href': modelAttributeValue.link
                }, {
                    // Make mention attribute to be wrapped by other attribute elements.
                    priority: 20,
                    // Prevent merging mentions together.
                    id: modelAttributeValue.uid
                });
            },
            converterPriority: 'high'
        });
    }
};
window.attachPostEditor = function (element) {
    return ClassicEditor
        .create(element, {
            removePlugins: ['Heading', 'RestrictedEditingMode', 'HtmlEmbed', 'Title', 'ImageToolbar', 'ImageResize', 'ImageUpload', 'ImageInsert', 'LinkImage', 'Image', 'AutoImage', 'MediaEmbed'],
            toolbar: [
                'bold', 'italic', '|', 'bulletedList', 'numberedList', 'blockQuote'
            ],
            mediaEmbed: {
                previewsInData: true
            },
            htmlSupport: {
                allow: [
                    {
                        name: /^(div|p|span|img)$/,
                        classes: ['link-preview__title', 'link-preview__description', 'link-preview__img'],
                        attributes: true
                    }
                ]
            },
            link: {
                defaultProtocol: 'https://'
            },
            postEditor: true,
            linkPasted: false
        })
        .catch(error => {
            console.error(error);
        });

};
window.attachLinkPreview = function (link, editor) {
    if (editor.config.get('postEditor') && (typeof postDropzone !== 'undefined') && (/\.(jpeg|jpg|gif|png)(\?|$)/i.test(link))) {
        handleEditorImageLink(link, editor, postDropzone)
    } else {
        handleEditorLink(link, editor)
    }
}

function handleEditorLink(link, editor) {
    andels.plugins.utils.attacheSpinner();
    $.ajax({
        type: 'POST',
        url: '/api/url-preview',
        data: {url: link},
        success: function (data) {
            let html = `<a href="${link}">${link}</a>`;

            if (data.title !== null && data.title.length > 0) {
                 html = html + '<span class="link-preview__title">' + data.title + '</span>';

                if (data.description !== null && data.description.length > 0) {
                    html = html + '<span class="link-preview__description">' + data.description + '</span>';
                }
                if (data.image !== null && data.image.length > 0) {
                    html = html + '<img src="' + data.image + '" alt="" class="link-preview__img">';
                }
            }

            const viewFragment = editor.data.processor.toView(html);
            const modelFragment = editor.data.toModel(viewFragment);
            editor.commands.get('undo').execute();
            editor.model.insertContent(modelFragment);
            window.andels.plugins.utils.linkPreviewStyles();
        },
        complete: function () {
            andels.plugins.utils.removeSpinner();
        }
    });
}

function handleEditorImageLink(link, editor, dropzone) {
    const xhr = new XMLHttpRequest();

    xhr.responseType = 'blob';
    xhr.onload = function () {
        if (xhr.status === 200) {
            const blob = new Blob([xhr.response], {type: 'image/png'});
            blob.name = 'image.png';
            dropzone.addFile(blob);
            $(dropzone.element).show();

            editor.commands.get('undo').execute();
        }
    };
    xhr.onerror = function () {
        handleEditorLink(link, editor);
    }
    xhr.open('GET', link);
    xhr.send();

}