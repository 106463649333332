const taskmanagerPlugin = {
    key: 'taskmanager',
    newTask: 1,

    pluginBehavior: (andels, $) => {
        andels.plugins.utils.datetimePickerBySelector('[id^=deadline]');
        taskmanagerPlugin.addTask(andels);
        taskmanagerPlugin.removeNew();
        taskmanagerPlugin.taskRemoveRestore();
        taskmanagerPlugin.initInviteResponsible(".invite_responsible_search");
        taskmanagerPlugin.initTaskCheck();
        taskmanagerPlugin.initUseDeadline();
        taskmanagerPlugin.initUseResponsible();
        taskmanagerPlugin.initRemoveResponsible();
    },
    addTask: (andels) => {
        $('.add-task').on('click', function () {
            let html = $('#task-fields-holder').html()
                .replace('name-placeholder', `new_task[${taskmanagerPlugin.newTask}][title]`)
                .replace(/deadline-placeholder/g, `new_task[${taskmanagerPlugin.newTask}][deadline]`)
                .replace(/deadline-use-placeholder/g, `new_task[${taskmanagerPlugin.newTask}][use_deadline]`)
                .replace(/responsible-use-placeholder/g, `new_task[${taskmanagerPlugin.newTask}][use_responsible]`)
                .replace('responsible-task-placeholder', taskmanagerPlugin.newTask)

            $('.task-placeholder').prepend(html);
            andels.plugins.utils.datetimePickerBySelector(`[name^="new_task[${taskmanagerPlugin.newTask}][deadline]"]`);
            taskmanagerPlugin.initInviteResponsible(`[data-task="${taskmanagerPlugin.newTask}"]`);

            taskmanagerPlugin.newTask++;
            taskmanagerPlugin.removeNew();
        });
    },
    removeNew: () => {
        $('.remove-new-task').on('click', function () {
            $(this).parents('.task-info').remove();
            if ($('.task-info').length === 0) {
                $('#editTasks button[type="submit"]').addClass('hidden');
                $('.empty').show();
            }
        });
    },
    taskRemoveRestore: () => {
        $('.remove-task').on('click', function () {
            $(this).parents('.task-info').find('input').attr('disabled', 'disabled');
            $(this).parents('.task-info').find('.fa-trash-alt').hide();
            $(this).parents('.task-info').find('.fa-trash-restore-alt').hide();
            $(this).next('.restore-task').show();
            $('.deleted-fields--holder').append(`<input type="hidden" data-id="task${$(this).data('task')}" name="delete_tasks[]" value="${$(this).data('task')}" />`);
        });
        $('.restore-task').on('click', function () {
            $(this).parents('.task-info').find('input').removeAttr('disabled');
            $(this).hide();
            $(this).parents('.task-info').find('.fa-trash-alt').show();
            $(`[data-id="task${$(this).data('task')}"]`).remove();
        });
    },
    initInviteResponsible: (selector) => {
        $(selector).on('keyup', function () {
            if ($(this).val().length > 3) {
                let query = $(this).val();
                let container = $(this).closest('.form-group');
                let resultsPlaceholder = $(container).find('.responsibles__results--placeholder').first();
                let allResultsPlaceholder = $(container).find('.search__results--placeholder').first();
                let isNewTask = $(this).data('new') == "1";
                let taskID = $(this).data('task');

                $.ajax({
                    type: 'GET',
                    url: '/task-manager/responsible',
                    data: {"query": query, "taskID": taskID, 'newTask': isNewTask},
                    success: function (data) {
                        if (data.data) {
                            resultsPlaceholder.html('');
                            $.each(data.data, (key, value) => {
                                resultsPlaceholder.append(`<div class="confirm__search cursorPointer mb-1" data-id="${key}">${value}</div>`);
                            });
                            resultsPlaceholder.show();
                            $('.confirm__search').on('click', function () {
                                $(allResultsPlaceholder).append(taskmanagerPlugin.inviteResponsibleResultTemplate($(this).data('id'), $(this).html(), taskID, isNewTask));
                                $(selector).val('');
                                $(resultsPlaceholder).html('');
                                $(resultsPlaceholder).hide();
                            });
                        }
                    },
                    error: function (data) {
                        $(allResultsPlaceholder).prepend(`<div class="alert alert-warning">${data.responseJSON.data}</div>`);
                    },
                });
            }
        });
    },
    inviteResponsibleResultTemplate: (key, value, taskID, isNew) => {
        let newTask = isNew ? 'new_' : '';
        if ($(`#responsible${newTask}-${taskID}-${key}`).length === 0) {
            return `<div class="form-group form-check d-flex align-items-center">
                        <input type="hidden" name="${newTask}task[${taskID}][responsible][${key}]" id="responsible${newTask}-${taskID}-${key}">
                        <span class="mr-2">${value}</span>
                        <i class="fas fa-times remove-responsible"></i>
                    </div>`;
        } else {
            return '';
        }
    },
    initTaskCheck: () => {
        $('.check-task').on('change', function () {
            const taskID = $(this).data('id');
            const taskmanagerID = $(this).data('taskmanager-id');
            const progressBar = $(`#taskmanager-${taskmanagerID}`);

            const done = $(this).is(':checked') ? 1 : 0;
            const task = $(this).parents('.taskmanager__task').first();

            $.ajax({
                type: 'POST',
                url: '/task-manager/check',
                data: {"taskID": taskID, 'done': done},
                success: function (data) {
                    if (data.done == 1) {
                        task.addClass('done');
                    } else {
                        task.removeClass('done');
                    }

                    if(data.taskprogress >= 0 && data.taskprogress <= 100) {
                        progressBar.find('.taskmanager__progress--percentage').text(`${data.taskprogress}%`);
                        progressBar.find('.taskmanager__progress--done').css('width', `${data.taskprogress}%`)
                    }
                },
            });
        });
    },
    initUseDeadline: () => {
        $(document).on('change', '.form-deadline-show', function () {
            if ($(this).is(':checked')) {
                $(this).siblings('.form-deadline-calendar').addClass('show');
            } else {
                $(this).siblings('.form-deadline-calendar').removeClass('show');
            }
        });
    },
    initUseResponsible: () => {
        $(document).on('change', '.form-responsible-show', function () {
            if ($(this).is(':checked')) {
                $(this).siblings('.invite_responsible_search').addClass('show');
            } else {
                $(this).siblings('.invite_responsible_search').removeClass('show');
            }
        });
    },
    initRemoveResponsible: () => {
        $(document).on('click', '.remove-responsible', function () {
            $(this).parent().remove();
        });
    }

};
module.exports = taskmanagerPlugin;
