import I18n from './vendor/I18n';

window.translator = new I18n;

const Andels = require('./custom/Andels.js');
const authPlugin = require('./custom/auth.js');
const dawaPlugin = require('./custom/dawa.js');
const commonPlugin = require('./custom/common.js');
const commentsPlugin = require('./custom/comments.js');
const ratingPlugin = require('./custom/rating.js');
const postsPlugin = require('./custom/posts.js');
const utilsPlugin = require('./custom/utils.js');
const invitePlugin = require('./custom/invite.js');
const facilityPlugin = require('./custom/facility.js');
const profilePlugin = require('./custom/profile.js');
const membersPlugin = require('./custom/members.js');
const notificationsPlugin = require('./custom/notifications.js');
const mobilePlugin = require('./custom/mobile.js');
const systemPlugin = require('./custom/system_notification');
const pollPlugin = require('./custom/poll');
const businessPlugin = require('./custom/business');
const searchPlugin = require('./custom/search');
const tipsPlugin = require('./custom/tips');
const chatPlugin = require('./custom/chat');
const pantherPlugin = require('./custom/panther');
const groupsPlugin = require('./custom/groups');
const organizationsPlugin = require('./custom/organizations');
const taskmanagerPlugin = require('./custom/taskmanager');
const eventsPlugin = require('./custom/events');
const documentsPlugin = require('./custom/documents');
const selfservicePlugin = require('./custom/selfservice');


$(document).ready(function () {

    const height = parseInt($(window).height()) - $('#app>nav').height();
    $('#sidebar__left').css('height', height + 'px');
    if ($('.welcome--menu').length) {
        $('.welcome--menu').css('height', $(window).height() + 'px');
    }
    if (parseInt($('.container__general--notifications').height()) > 0) {
        $('.container__general--notifications')
            .next('.content__center')
            .css('min-height', `calc(100% - ${parseInt($('.container__general--notifications').height())}px)`)
    }
    require('./standalone/ckeditor_source');
    require('./standalone/ckEditor.js');
    require('./standalone/lightgallery.js')

    window.andels = new Andels({}, $);
    window.andels.addPlugin(authPlugin);
    window.andels.addPlugin(dawaPlugin);
    window.andels.addPlugin(commonPlugin);
    window.andels.addPlugin(commentsPlugin);
    window.andels.addPlugin(ratingPlugin);
    window.andels.addPlugin(postsPlugin);
    window.andels.addPlugin(utilsPlugin);
    window.andels.addPlugin(invitePlugin);
    window.andels.addPlugin(facilityPlugin);
    window.andels.addPlugin(profilePlugin);
    window.andels.addPlugin(membersPlugin);
    window.andels.addPlugin(notificationsPlugin);
    window.andels.addPlugin(mobilePlugin);
    window.andels.addPlugin(systemPlugin);
    window.andels.addPlugin(pollPlugin);
    window.andels.addPlugin(businessPlugin);
    window.andels.addPlugin(searchPlugin);
    window.andels.addPlugin(tipsPlugin);
    window.andels.addPlugin(chatPlugin);
    window.andels.addPlugin(pantherPlugin);
    window.andels.addPlugin(groupsPlugin);
    window.andels.addPlugin(organizationsPlugin);
    window.andels.addPlugin(taskmanagerPlugin);
    window.andels.addPlugin(eventsPlugin);
    window.andels.addPlugin(documentsPlugin);
    window.andels.addPlugin(selfservicePlugin);
    window.andels.attachBehaviors();
    require('./standalone/calendar.js');
    require('./standalone/gallery.js');
    require('./standalone/swiper.js');
});
