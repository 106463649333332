import 'lightgallery/lib/plugins/thumbnail/lg-thumbnail'
import 'lightgallery/scss/lightgallery-bundle.scss'
import lightGallery from "lightgallery";

window.attachLightGallery = function (galleryElement) {
    galleryElement.addEventListener('lgInit', (event) => {
        const children = $(event.target).children();
        if(children.length === 1) {
            $(children[0]).height('auto')
        }
        if(children.length === 2) {
            $(children[0]).height('auto')
            $(children[0]).width('48%')
            $(children[1]).height('auto')
            $(children[1]).width('48%')
        }
    });
    lightGallery(galleryElement, {
        licenseKey: '8a65713a-4c05-4ac9-aed8-94ce7cb30dbb', //prod
        // licenseKey:  '0000-0000-000-0000',
    });
}



