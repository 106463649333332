const utilsPlugin = {
    key: 'utils',


    attacheDropzone: function (element, options) {
        return new Dropzone(element, options);
    },

    filesDropzone: function (url, form, autoProcessValue = false) {
        return {
            url: url,
            maxFilesize: 100,
            parallelUploads: 8,
            acceptedFiles: "image/*,application/*",
            uploadMultiple: true,
            paramName: 'files',
            timeout: 60000,
            autoProcessQueue: autoProcessValue,
            addRemoveLinks: true,
            init: function () {
                this.on('sending', function (file, xhr, formData) {
                    const data = form.serializeArray();
                    $.each(data, function (key, el) {
                        formData.append(el.name, el.value);
                    });
                });
                this.on('addedfile', function () {
                    $('.alert.alert-danger').remove();
                });
            },
            success: function (file, response) {
                window.location.reload();
            },
            error: function (file, response) {
                form.find('#dropzone').prepend(`<div class="alert alert-danger">${response}</div>`);
            }
        };
    },
    singleFileDropzone: function (url, form, autoProcessValue = false) {
        return {
            url: url,
            maxFilesize: 100,
            parallelUploads: 1,
            maxFiles: 1,
            acceptedFiles: "image/*,application/*",
            uploadMultiple: false,
            paramName: 'files',
            timeout: 60000,
            autoProcessQueue: autoProcessValue,
            addRemoveLinks: true,
            init: function () {
                this.on('sending', function (file, xhr, formData) {
                    const data = form.serializeArray();
                    $.each(data, function (key, el) {
                        formData.append(el.name, el.value);
                    });
                });
                this.on('addedfile', function () {
                    $('.alert.alert-danger').remove();
                });
            },
            success: function (file, response) {
                window.location.reload();
            },
            error: function (file, response) {
                form.find('#dropzone').prepend(`<div class="alert alert-danger">${response}</div>`);
            }
        };
    },
    attacheSpinner: () => {
        $('.main-container').append('<div class="loading-layout"><div class="spinner-grow text-info" role="status"></div></div>');
    },
    attacheLoader: () => {
        $('.main-container').append('<div class="loading-layout"></div>');
    },
    removeSpinner: () => {
        $('.loading-layout').remove();
    },
    scrollTo: (element) => {
        if ($(element).length) {
            $('html, body').animate({
                    scrollTop: $(element).offset().top,
                },
                500,
                'linear'
            );
        } else {
            $('html, body').animate({
                    scrollTop: $('footer').offset().top,
                },
                500,
                'linear'
            );
        }
    },
    processHash() {
        if (window.location.hash) {
            const hash = window.location.hash;
            if (hash.indexOf('#comment-') === 0) {
                $(hash).parents('.post__comments ').show();
                if ($(hash).parent('.comment-child').length && $(hash).parent('.comment-child').length > 5) {
                    const id = $(hash).parent('.comment-child').data('id');
                    $(`.show-children[data-id="${id}"]`).trigger('click');
                }
                utilsPlugin.scrollTo(hash);
            } else if (hash.indexOf('#questions') === 0 && $('#questions').length) {
                $('#questions').trigger('click');
            }
        }
    },
    singleDatePicker: (from = 'single_from') => {
        $(`#${from}`).datetimepicker({
            format: 'DD-MM-YYYY',
            locale: window.lang,
            icons: {
                time: "fas fa-clock",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fas fa-chevron-left",
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-day',
                clear: 'fas fa-trash',
                close: 'fas fa-times'
            }
        });
    },
    datetimePicker: (from = 'from', to = 'to') => {
        $(`#${from}`).datetimepicker({
            format: 'DD-MM-YYYY HH:mm',
            locale: window.lang,
            icons: {
                time: "fas fa-clock",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fas fa-chevron-left",
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-day',
                clear: 'fas fa-trash',
                close: 'fas fa-times'
            }
        });
        $(`#${to}`).datetimepicker({
            format: 'DD-MM-YYYY HH:mm',
            locale: window.lang,
            icons: {
                time: "fas fa-clock",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fas fa-chevron-left",
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-day',
                clear: 'fas fa-trash',
                close: 'fas fa-times'
            },
            useCurrent: false
        });
        $(`#${from}`).on("dp.change", function (e) {
            $(`#${to}`).data("DateTimePicker").minDate(e.date);
        });
        $(`#${to}`).on("dp.change", function (e) {
            $(`#${from}`).data("DateTimePicker").maxDate(e.date);
        });
    },
    datetimePickerBySelector: (selector) => {
        $(selector).datetimepicker({
            format: 'DD-MM-YYYY HH:mm',
            locale: window.lang,
            icons: {
                time: "fas fa-clock",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: "fas fa-chevron-left",
                next: 'fas fa-chevron-right',
                today: 'fas fa-calendar-day',
                clear: 'fas fa-trash',
                close: 'fas fa-times'
            }
        });
    },
    calendarDatePicker: (from = 'from_date', to = 'to_date', constraints = {}) => {
        let minDate = new Date();

        minDate.setHours(0, 0, 0, 0);

        let params = {
            format: 'DD-MM-YYYY',
            locale: window.lang,
            useCurrent: false,
            minDate: minDate,
            icons: {
                previous: "fas fa-chevron-left",
                next: 'fas fa-chevron-right',
            }
        }

        if(typeof constraints === 'object' && Object.keys(constraints).length > 0) {
            const {from_date, to_date} = constraints;
            params.minDate = new Date(from_date);
            params.maxDate = new Date(to_date);
        }

        $(`#${from}`).datetimepicker(params);
        $(`#${to}`).datetimepicker(params);

        $(`#${from}`).on("dp.change", function (e) {
            $(`#${to}`).data("DateTimePicker").minDate(e.date);
        });
        $(`#${to}`).on("dp.change", function (e) {
            $(`#${from}`).data("DateTimePicker").maxDate(e.date);
        });
    },
    calendarTimePicker: (from = 'time_from', to = 'time_to') => {
        let params = {
            format: 'HH:mm',
            icons: {
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down"
            },
            useCurrent: false
        }
        $(`#${from}`).datetimepicker(params);
        $(`#${to}`).datetimepicker(params);
    },
    linkPreviewStyles: () => {
        $('.link-preview__title').parent().css({
            'display': 'flex',
            'flex-direction': 'column',
            'margin': '10px 0',
            'padding': '20px 10px',
            'background-color': '#F2F4F4'
        });
        $('.link-preview__title').siblings('a').attr('target', '_blank');
    },
    detectAndInsertNewMember: (modelShortClassName) => {
        const membersID = $(`#members-${modelShortClassName}`);
        if (membersID.length) {
            window.Echo.channel(`members-${modelShortClassName}-${membersID.val()}`)
                .listen('UserJoined', (memberData) => {
                    const lastMember = membersID.siblings('.members').children().last();
                    const lastMemberOffset = parseInt($(lastMember).css('left'));
                    let avatar = memberData.avatarIsSet === 'true' ? `<img src="${memberData.avatarURL}">` : `<span class="user-initials">${memberData.userInitials}</span>`;
                    let memberHTML = `<a href="${memberData.userURL}" style="left: ${lastMemberOffset + 15}px;" title="${memberData.userName}">
                                            <span class="circle-35">${avatar}</span>
                                        </a>`;
                    membersID.siblings('.members').append(memberHTML);
                })
        }
    },

    getCropperBox: (editorTitle) => {
        let editor = document.createElement('div');
        editor.className = 'crop--editor';
        document.body.appendChild(editor);

        let container = document.createElement('div');
        container.className = 'btn--container shadow rounded';

        let editorTitleElement = document.createElement('span');
        editorTitleElement.className = 'editor-explanation';
        editorTitleElement.textContent = editorTitle;

        // Create confirm button at the top left of the viewport
        let buttonConfirm = document.createElement('button');
        buttonConfirm.className = 'btn btn-info';
        buttonConfirm.textContent = window.translator.trans('admin.confirm');

        let buttonCancel = document.createElement('button');
        buttonCancel.className = 'btn btn-outline-secondary mr-3';
        buttonCancel.textContent = window.translator.trans('admin.cancel');

        container.appendChild(editorTitleElement);
        container.appendChild(buttonCancel);
        container.appendChild(buttonConfirm);
        editor.append(container);

        buttonCancel.addEventListener('click', function () {
            editor.remove();
        });

        return { editor, button: buttonConfirm }
    },
    loadNextPageItems: (url, container) => {
        const pageNumberInput = $('#pageN');
        const currentPageNumber = parseInt(pageNumberInput.val());
        const listType = $('#listType').length > 0 ? $('#listType').val() : 'all';
        const searchTerm = $('#listSearch').length > 0 ? $('#listSearch').val() : null;
        const orderBy = $('#orderBy').length > 0 ? $('#orderBy').val() : null;

        utilsPlugin.attacheSpinner();
        $.ajax({
            url: url,
            data: {
                page: currentPageNumber,
                listType: listType,
                searchTerm: searchTerm,
                orderBy: orderBy
            },
            success: function (result) {
                if (result.itemsCnt > 0) {
                    pageNumberInput.val(currentPageNumber + 1);
                    container.append(result.html);
                } else {
                    $(window).off('scroll.list');
                }
            },
            complete: function () {
                utilsPlugin.removeSpinner();
            }
        });
    },
    trackUmamiEvent: (eventName, additionalParams = {}) => {
        if(typeof umamiTrackerEnabled !== 'undefined' && umamiTrackerEnabled) {
            if(Object.keys(additionalParams).length > 0) {
                umami.track(eventName, additionalParams);
            } else {
                umami.track(eventName);
            }
        }
    }
};
module.exports = utilsPlugin;
