const businessPlugin = {
    key: 'business',

    pluginBehavior: (andels, $) => {
        businessPlugin.attacheUpdate(andels);
        businessPlugin.attacheDelete();
        businessPlugin.showRateTypes();
        setTimeout(businessPlugin.attacheRentDateCheck, 500);
        businessPlugin.initAjaxPagination();
    },
    attacheDelete: () => {
        $('.discount-delete').on('click', function () {
            $(`#discountDestroy #discount`).val($(this).data('id'));
            $('#deleteConfirmation #content--placeholder').html(
                $(this).parents('.dropleft').prev('div').html()
            );
            $('#deleteConfirmation').modal('show');
        });
    },
    attacheUpdate: (andels) => {
        $('.discount-update').on('click', function () {
            businessPlugin.ajaxCall(`/discount/${$(this).data('business')}/${$(this).data('id')}/edit`, andels)
        });
    },
    ajaxCall: (url, andels) => {
        $.ajax({
            type: 'GET',
            url: url,
            success: function (data) {
                $('#editDiscount .modal-body').html(data);
                andels.plugins.utils.datetimePicker();
                $('#editDiscount').modal('show');
            },
            error: function () {
                window.location.reload();
            },
        });
    },
    showRateTypes: () => {
        $('#sale_type').on('change', function () {
            if ($(this).val() == 'Rent') {
                $('.rate-types').show();
            } else {
                $('.rate-types').hide();
            }
        });
    },
    attacheRentDateCheck: () => {
        $(document).on(
            "dp.change",
            "#bookRentForm #from_date, #bookRentForm #to_date, #bookRentForm #time_from, #bookRentForm #time_to",
            function (e) {
                let modalOpened = $('#bookRent').hasClass('show');
                let errorEl = $('#bookRent .error');
                let form = $('#bookRent form');

                if (modalOpened) {
                    businessPlugin.checkRentAvailable(form, errorEl);
                }
            });
    },
    checkRentAvailable: (form, errorBox) => {
        $.ajax({
            url: '/announcement/rent/verify',
            data: form.serialize(),
            success: function () {
                errorBox.hide();
            },
            error: function (data) {
                errorBox.html(data.responseJSON.error);
                errorBox.show();
            }
        });
    },
    getRentPrice: (announcement, from_date, to_date, time_from, time_to) => {
        return $.ajax({
            type: 'GET',
            url: `/announcement/rent/get-price`,
            data: {announcement, from_date, to_date, time_from, time_to},
            success: function (data) {
                if (data.price) {
                    $('#averagePrice').text(`${translator.trans('business.estimated_price')} ${data.price}`).show();
                } else {
                    $('#averagePrice').hide();
                }
            },
            error: function (data) {
                $('#averagePrice').hide();
            }
        });
    },
    initAjaxPagination: () => {
        if ($('#businessesList').length) {
            $(window).on('scroll.list', function () {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    andels.plugins.utils.loadNextPageItems('/more/businesses', $('#itemsList'));
                }
            });
        }
    },
};
module.exports = businessPlugin;
