const eventsPlugin = {
    key: 'events',

    pluginBehavior: (andels, $) => {
        eventsPlugin.attachFormTip();
        eventsPlugin.generateEventVisibilityTip();
        eventsPlugin.initDateTimePicker();
        andels.plugins.utils.detectAndInsertNewMember('event');
    },
    attachFormTip: () => {
        $('#eventCorporationCheck, #eventPrivate').on('change', function () {
            eventsPlugin.generateEventVisibilityTip();
        });
    },
    generateEventVisibilityTip: () => {
        if ($('#eventPrivate').length || $('#eventCorporationCheck').length) {
            const isPrivate = $('#eventPrivate').is(':checked');
            const isCorporate = $('#eventCorporationCheck').is(':checked');
            let tip = '';

            if (!isPrivate && !isCorporate) {
                tip = window.translator.trans('form.event_visibility_tip.public');
            } else if (!isPrivate && isCorporate) {
                tip = window.translator.trans('form.event_visibility_tip.public_corporate');
            } else if (isPrivate && !isCorporate) {
                tip = window.translator.trans('form.event_visibility_tip.private');
            } else if (isPrivate && isCorporate) {
                tip = window.translator.trans('form.event_visibility_tip.private_corporate');
            }

            $('#eventVisibilityTip').text(tip);
        }
    },
    initDateTimePicker: () => {
        let minDate = new Date();
        minDate.setHours(0, 0, 0, 0);

        let params = {
            format: 'DD-MM-YYYY',
            locale: window.lang,
            useCurrent: false,
            minDate: minDate,
            icons: {
                previous: "fas fa-chevron-left",
                next: 'fas fa-chevron-right',
            }
        }

        $(`#event_from_date`).datetimepicker(params);
        $(`#event_to_date`).datetimepicker(params);

        $(`#event_from_date`).on("dp.change", function (e) {
            $(`#event_to_date`).data("DateTimePicker").minDate(e.date);
        });
        $(`#event_to_date`).on("dp.change", function (e) {
            $(`#event_from_date`).data("DateTimePicker").maxDate(e.date);
        });
    }
}

module.exports = eventsPlugin;
