const pollPlugin = {
    key: 'poll',
    newOption: 1,

    pluginBehavior: (andels, $) => {
        if ($('#lastId').length) {
            pollPlugin.newOption = $('#lastId').val();
        }

        pollPlugin.addOption();
        pollPlugin.questionRemoveRestore();
        pollPlugin.initPoll();
    },
    addOption: () => {
        $('.add-option').on('click', function () {
            let html = $('#option-holder').html()
                .replace('max_check-placeholder', `poll_options[new_max_check][${pollPlugin.newOption}]`)
                .replace('name-placeholder', `poll_options[new_option][${pollPlugin.newOption}]`)
            $('.option-placeholder').append(html);

            pollPlugin.newOption++;
            pollPlugin.removeNew();

            if ($('#editQuestions button[type="submit"]').hasClass('hidden')) {
                $('#editQuestions button[type="submit"]').removeClass('hidden');
            }
        });
    },
    removeNew: () => {
        $('.remove-new-option').on('click', function () {
            $(this).parents('.option-info').remove();
            if (
                !($('.option-placeholder').find('.remove-option:visible').length) &&
                !($('.option-placeholder').find('.remove-new-option:visible').length) &&
                !($('#editQuestions button[type="submit"]').hasClass('hidden'))
            ) {
                $('#editQuestions button[type="submit"]').addClass('hidden');
            }
        });
    },
    questionRemoveRestore: () => {
        $('.remove-option').on('click', function () {
            $(this).parents('.option-info').find('input').attr('disabled', 'disabled');
            $(this).parents('.option-info').find('.fa-trash-alt').hide();
            $(this).parents('.option-info').find('.fa-trash-restore-alt').hide();
            $(this).next('.restore-option').show();
            $('.deleted-fields--holder').append(`<input type="hidden" data-id="option${$(this).data('option')}" name="poll_options[delete_options][]" value="${$(this).data('option')}" />`);

            if (
                !($('.option-placeholder').find('.remove-option:visible').length) &&
                !($('.option-placeholder').find('.remove-new-option:visible').length) &&
                !($('#editQuestions button[type="submit"]').hasClass('hidden'))
            ) {
                $('#editQuestions button[type="submit"]').addClass('hidden');
            }
        });
        $('.restore-option').on('click', function () {
            $(this).parents('.option-info').find('input').removeAttr('disabled');
            $(this).hide();
            $(this).parents('.option-info').find('.fa-trash-alt').show();
            $(`[data-id="option${$(this).data('option')}"]`).remove();

            if ($('#editQuestions button[type="submit"]').hasClass('hidden')) {
                $('#editQuestions button[type="submit"]').removeClass('hidden');
            }
        });
    },
    initPoll: () => {
        $(document).on('change', '.poll-vote', function () {
           const voted = $(this).is(':checked') ? 1 : 0;
           const optionId = $(this).data('id');

            $.ajax({
                type: 'POST',
                url: '/poll/vote',
                data: {"optionID": optionId, 'voted': voted},
                success: function (result) {
                    if (result.html.length > 0 && result.poll_id > 0) {
                        andels.plugins.utils.trackUmamiEvent('Poll vote')

                        $(`#postPoll-${result.poll_id}`).html(result.html);
                    }
                },
            });
        });
    }
};
module.exports = pollPlugin;
