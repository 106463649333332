const documentsPlugin = {
    key: 'documents',

    pluginBehavior: (andels, $) => {
        documentsPlugin.initFileOrderBy();
    },
    initFileOrderBy: () => {
        if ($('#filesOrderBy').length) {
            $('#filesOrderBy').on('change', function () {
               const value = $(this).val();

               window.location.href = window.location.href.split('?')[0] + '?orderBy=' + value;
            });
        }
    }

};
module.exports = documentsPlugin;
