const organizationsPlugin = {
    key: 'organizations',

    pluginBehavior: (andels, $) => {
        organizationsPlugin.initOrganizationFileUpload();
        organizationsPlugin.initOrganizationFileDestroy();
        andels.plugins.utils.detectAndInsertNewMember('organization');
        organizationsPlugin.initAjaxPagination();
        organizationsPlugin.attachOrganizationMemberSearch();
        organizationsPlugin.attachSendJoinOrganizationRequest();
        organizationsPlugin.handleJoinOrganizationRequest();
        organizationsPlugin.attachSuborganizationInfoModal();
        organizationsPlugin.attachExpandCollapseTree();
    },
    initOrganizationFileUpload: () => {
        $('.organization-file-upload').on('click', function () {
            const organization_id = $(this).data('id');

            if (!organizationsPlugin.dropZone) {
                organizationsPlugin.dropZone = andels.plugins.utils.attacheDropzone(
                    "#organizationFilesUpload #dropzone",
                    andels.plugins.utils.singleFileDropzone(`/organization/upload-file/${organization_id}`, $('#organizationFilesUpload'))
                );
            }
            $('#uploadConfirmation').modal('show');
            $('.organization-files-upload').on('click', function () {
                if (organizationsPlugin.dropZone) {
                    organizationsPlugin.dropZone.processQueue();
                }
            });
        });
    },
    initOrganizationFileDestroy: () => {
        $('.organization-file-delete').on('click', function (event) {
            event.preventDefault();
        });
    },
    initAjaxPagination: () => {
        if ($('#organizationsList').length) {
            $(window).on('scroll.list', function () {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    andels.plugins.utils.loadNextPageItems('/more/organizations', $('#itemsList'));
                }
            });
        }
    },
    attachOrganizationMemberSearch: () => {
        const results_placeholder = $('#user__results--placeholder');

        $("#organization_members_search").on('keyup', function () {
            const query = $(this).val();
            const organization_id = $(this).data('parent-id');

            if (query.length > 2) {
                $.ajax({
                    type: 'GET',
                    url: '/api/organization/members',
                    data: {query, organization_id},
                    success: function (data) {
                        results_placeholder.html('');
                        if (data.data) {
                            $.each(data.data, (key, value) => {
                                let user = `<div class="confirm__search cursorPointer mb-1" data-id="${key}">${value}</div>`;
                                results_placeholder.append(user);
                            });
                            organizationsPlugin.addToResults();
                        } else {
                            if(data.newUserInvited !== undefined && data.newUserInvited) {
                                results_placeholder.append(`<div class="mb-1">${window.translator.trans('form.search_invite_new_member_invited', {member: query})}</div>`);
                            } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(query)) {
                                results_placeholder.append(`<div class="confirm_new_user_search cursorPointer mb-1" data-value="${query}">${window.translator.trans('form.search_invite_new_member', {member: query})}</div>`);
                                organizationsPlugin.addToResults();
                            } else {
                                results_placeholder.append(`<div class="mb-1">${window.translator.trans('form.search_user_not_found')}</div>`);
                            }
                        }
                        results_placeholder.show();
                    },
                    error: function (data) {
                        $('#search__results--placeholder').prepend(`<div class="alert alert-warning">${data.responseJSON.data}</div>`);
                    },
                });
            }
        });
    },
    addToResults: () => {
        $('.confirm__search').on('click', function () {
            const id = $(this).data('id');
            const name = $(this).html();
            let memberHtml = '';

            if ($(`[name="member_admin[${id}]"]`).length === 0) {
                memberHtml = `<div class="form-group form-check mb-0 d-flex align-items-center">
                        <input type="hidden" name="member_admin[${id}]" value="1">
                        <span class="mr-2">${name}</span>
                        <i class="fas fa-times remove-invited"></i>
                    </div>`;
            }

            $('#search__results--placeholder').append(memberHtml);
            $('#organization_members_search').val('');
            $(`#user__results--placeholder`).html('').hide();
        });

        $('.confirm_new_user_search').on('click', function () {
            const email = $(this).data('value');

            let memberHtml = $(`[value="${email}"]`).length === 0 ? `<div class="form-group form-check mb-0 d-flex align-items-center">
                    <input type="hidden" name="invite_new_nabonet_user[]" value="${email}">
                    <span class="mr-2">${email}</span>
                    <i class="fas fa-times remove-invited"></i>
                </div>` : '';


            $('#search__results--placeholder').append(memberHtml);
            $('#organization_members_search').val('');
            $(`#user__results--placeholder`).html('').hide();
        });
    },
    attachSendJoinOrganizationRequest: () => {
        $(document).on('change', '#joinOrganizationForm #parent_organization_name', function (e) {
            const orgName = $(this).val();

            $("#parent_organization_id_list").find("option").each(function() {
                if ($(this).val() === orgName) {
                    const org_id = $(this).data('id');
                    $('#parent_organization_id').val(org_id);
                }
            })
        });

        $('#sendJoinOrganizationRequest').on('click', function () {
            if ($('#parent_organization_id').val()) {
                const organization_id = $('#organization_id').val();

                $('#sendJoinOrganizationRequest').hide();
                $.ajax({
                    type: 'POST',
                    url: `/organization/${organization_id}/suborganizations/join-request`,
                    data: $('#joinOrganizationForm').serialize(),
                    success: function () {
                        window.location.reload();
                    },
                    error: function (data) {
                        $('#search__results--placeholder').prepend(`<div class="alert alert-warning">${data.responseJSON.error}</div>`);
                        $('#joinOrganizationForm').show();
                    },
                });
            } else {
                $('#search__results--placeholder').prepend(`<div class="alert alert-warning">${window.translator.trans('form.choose_member')}</div>`);
            }
        });
    },
    handleJoinOrganizationRequest: () => {
        $(document).on('click', '[data-accept].orgjoinrequest', function () {
            const action = $(this).data('accept');
            const request = $(this).data('request-id');
            const organization_id = $(this).data('parent-id');

            $.ajax({
                type: 'POST',
                url: `/organization/${organization_id}/suborganizations/join-handle`,
                data: {action: action, request_id: request},
                success: function () {
                    window.location.reload();
                },
                error: function (data) {

                },
            });
        });
    },
    attachSuborganizationInfoModal: () => {
        $('.organization-tree--item-manage').on('click', function (e) {
            e.stopPropagation();
        });

        $(document).on('click', '.show-suborg-info', function (e) {
            e.stopPropagation();
            const sub_id = $(this).data('suborg-id');
            const organization_id = $('#organization_id').val();
            const modal = $('#suborgInfo');

            $.ajax({
                url: `/organization/${organization_id}/suborganizations/about`,
                data: {sub_id},
                success: function (result) {
                    if(result.title.length > 0) {
                        modal.find('#suborgInfoLabel').text(result.title);
                    }

                    if(result.html.length > 0) {
                        modal.find('.modal-body').html(result.html);
                        modal.modal('show');
                    }
                },
            });
        });
    },
    attachExpandCollapseTree: () => {
        $(document).on('click','.allsubsexpand', function() {
            $('.has-subs').each(function() {
                $(this).attr('aria-expanded', 'true');
                $(this).siblings('.suborg-tree-collapse').addClass('show');
            });

            $(this).addClass('allsubscollapse').removeClass('allsubsexpand');
            $(this).text(window.translator.trans('organization.suborganizations.collapse'))
        });
        $(document).on('click', '.allsubscollapse', function() {
            $('.has-subs').each(function() {
                $(this).attr('aria-expanded', 'false');
                $(this).siblings('.suborg-tree-collapse').removeClass('show');
            });

            $(this).addClass('allsubsexpand').removeClass('allsubscollapse');
            $(this).text(window.translator.trans('organization.suborganizations.unfold'))
        })
    }
};
module.exports = organizationsPlugin;
