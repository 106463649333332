const membersPlugin = {
  key: 'members',

  pluginBehavior: (andels, $) => {
    $('[data-toggle="tooltip"]').tooltip();
    $('#members_management .status').on('click', function () {
      const id = $(this).parents('.row').data('id');
      let status = 0;
      if ($(this).is(':checked')) {
        status = 1;
      }
      membersPlugin.ajaxCall('/board/member/status', {status: status, id: id, corporation: $('#corporation').val()},
        $(this),
        andels
      );
      $(`#boardMember-${id}`).removeAttr('checked');
      $(`#editor-${id}`).removeAttr('checked');
    });
    $('#members_management .activate-user').on('click', function () {
      const id = $(this).parents('.row').data('id');
      membersPlugin.ajaxCall('/board/member/status', {status: 1, id: id, corporation: $('#corporation').val()},
        $(this),
        andels,
        function() {
          const id = element.parents('.row').data('id');
          $(`#status-${id}`).prop('checked', 'checked').prop('disabled', false);
          element.remove();
        });
    });
    $('#members_management .account').on('click', function () {
      const id = $(this).parents('.row').data('id');
      const element = $(this);
      let data = {id: id};
      if ($('#corporation').length) {
        data.corporation = $('#corporation').val();
      }
      membersPlugin.ajaxCall('/member/account', data,
        element,
        andels,
        function() {
          window.location.reload();
        }
      );
    });
    $('#members_management .permission').on('click', function () {
      const id = $(this).parents('.row').data('id');
      const element = $(this);
      let attach = 0;
      if ($(this).is(':checked')) {
        attach = 1;
      }
      membersPlugin.ajaxCall('/member/permission', {id: id, 'permission': element.data('slug'), attach: attach},
        element,
        andels
      );
    });
    $('#members_management .add-role').on('click', function () {
      const id = $(this).parents('.row').data('id');
      let type = 0;
      if ($(this).is(':checked')) {
        type = 1;
      }
      membersPlugin.ajaxCall('/board/member/role',
        {role: $(this).data('role'), id: id, type: type, corporation: $('#corporation').val()},
        $(this),
        andels
      );
    });

  },
  ajaxCall: (url, data, element, andels, callbackFn = null) => {
    andels.plugins.utils.attacheSpinner();
    $.ajax({
      type: 'POST',
      url: url,
      data: data,
      success: function () {

        if(typeof callbackFn === 'function') {
          callbackFn();
        }
        andels.plugins.utils.removeSpinner();
      },
      error: function (data) {
        if (element) {
          if (set) {
            element.prop('checked', '');
          } else {
            element.prop('checked', 'checked');
          }
        }
        andels.plugins.utils.removeSpinner();
        $('.content__center').before(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
      },
    });
  },
};
module.exports = membersPlugin;
