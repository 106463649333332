import Swiper, {Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
Swiper.use([Navigation]);

const swiper = new Swiper('.swiper', {
    direction: 'horizontal',
    loop: false,
    slidesPerView: 6,
    spaceBetween: 10,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    on: {
        afterInit: function () {
            $('.content-template-wrapper').show();
        }
    }
});
