const groupsPlugin = {
    key: 'groups',

    pluginBehavior: (andels, $) => {
        groupsPlugin.initGroupFileUpload();
        groupsPlugin.initGroupFileDestroy();
        andels.plugins.utils.detectAndInsertNewMember('group');
        groupsPlugin.attachGroupVisibilityTip();
        groupsPlugin.generateGroupVisibilityTip();
        groupsPlugin.initAjaxPagination();
    },
    initGroupFileUpload: () => {
        $('.group-file-upload').on('click', function () {
            const group_id = $(this).data('id');

            if (!groupsPlugin.dropZone) {
                groupsPlugin.dropZone = andels.plugins.utils.attacheDropzone(
                    "#groupFilesUpload #dropzone",
                    andels.plugins.utils.singleFileDropzone(`/group/upload-file/${group_id}`, $('#groupFilesUpload'))
                );
            }
            $('#uploadConfirmation').modal('show');
            $('.group-files-upload').on('click', function () {
                if (groupsPlugin.dropZone) {
                    groupsPlugin.dropZone.processQueue();
                }
            });
        });
    },
    initGroupFileDestroy: () => {
        $('.group-file-delete').on('click', function (event) {
            event.preventDefault();
        });
    },
    attachGroupVisibilityTip: () => {
        $('#group_type').on('change', function () {
            groupsPlugin.generateGroupVisibilityTip();
        });
    },
    generateGroupVisibilityTip: () => {
        if ($('#groupVisibilityTip').length && $('#group_type').children().length) {
            const type = $('#group_type').val();
            let tip = '';

            switch (type) {
                case 'private':
                    tip = window.translator.trans('form.group_visibility_tip.private');
                    break;
                case 'corporate':
                    tip = window.translator.trans('form.group_visibility_tip.corporate');
                    break;
                default:
                    tip = window.translator.trans('form.group_visibility_tip.public');
            }

            $('#groupVisibilityTip').text(tip);
        }
    },
    initAjaxPagination: () => {
        if ($('#groupsList').length) {
            $(window).on('scroll.list', function () {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    andels.plugins.utils.loadNextPageItems('/more/groups', $('#itemsList'));
                }
            });
        }
    }
};
module.exports = groupsPlugin;
