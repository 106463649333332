const ratingPlugin = {
    key: 'rating',

    pluginBehavior: (andels, $) => {
        ratingPlugin.likeContent();
        ratingPlugin.unlikeContent();
        ratingPlugin.rateContent();
    },
    rateContent: () => {
        if ($('.rating--container').length) {
            $(document).on('click', '.rating--container [name="rating"]', function () {
                $.ajax({
                    type: 'POST',
                    url: '/rate/content',
                    data: {
                        id: $(this).parents('fieldset').data('id'),
                        type: $(this).parents('fieldset').data('type'),
                        value: $(this).val()
                    },
                    success: function () {
                        window.location.reload();
                    },
                    error: function (data) {
                        $('.rating--container').after(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                    },
                });
            });
        }
    },
    unlikeContent: () => {
        $(document).on('click', '.item_content .rating-liked', function (event) {
            event.preventDefault();
            const element = $(this);
            $.ajax({
                type: 'POST',
                url: '/unlike/content',
                data: {id: $(this).data('id'), type: $(this).data('type')},
                success: function (data) {
                    element.replaceWith(data.html);
                },
                error: function (data) {
                    $('.list-item--footer').after(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                },
            });
        });
    },
    likeContent: () => {
        $(document).on('click', '.item_content #likeContent', function (event) {
            event.preventDefault();
            const element = $(this);
            $.ajax({
                type: 'POST',
                url: '/like/content',
                data: {id: $(this).data('id'), type: $(this).data('type')},
                success: function (data) {
                    element.replaceWith(data.html);
                },
                error: function (data) {
                    $('.list-item--footer').after(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                },
            });
        });
    }
};
module.exports = ratingPlugin;
