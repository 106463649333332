const tipsPlugin = {
  key: 'tips',

  pluginBehavior: (andels, $) => {
    tipsPlugin.showTip();
    tipsPlugin.hideTips();
    tipsPlugin.nextTips();
    tipsPlugin.updateList(andels);
    tipsPlugin.getTip(andels);

  },
  showTip: () => {
    if($('#tipModal').length && $('#tipModal #showTip').val() != 0) {
      $('#hide_tips--container').show();
      $('#nextTip').addClass('d-flex').css('display', 'block');
      $('#tipModal').modal('show');
    }
  },
  hideTips: () => {
    $('#hideTips').on('click', function () {
      $('#tipModal').modal('hide');
      $.ajax({
        type: 'GET',
        url: '/hide/tips',
        success: function () {},
        error: function (data) {
          $(`#tipModal .modal-body`).prepend(`<div class="alert alert-danger">${data.responseJSON.error}</div>`);
        },
      });
    });
  },
  nextTips: () => {
    $('#nextTip').on('click', function () {
      $.ajax({
        type: 'GET',
        url: '/next/tip',
        success: function (data) {
          if (data.title) {
            $('#tipModal .modal-body .title').html(data.title);
            $('#tipModal .modal-body .body').html(data.description);
          } else {
            $('#tipModal #nextTip').remove();
          }
        },
        error: function (data) {
          $(`#tipModal .modal-body`).prepend(`<div class="alert alert-danger">${data.responseJSON.error}</div>`);
        },
      });
    });
  },
  updateList: (andels) => {
    $('#section').on('change', function () {
      const section = $(this).val();
      andels.plugins.utils.attacheSpinner();
      $.ajax({
        type: 'GET',
        url: '/library/tips',
        data: {section : section},
        success: function (data) {
          $('#tips__placeholder').html(data.html);
          andels.plugins.utils.removeSpinner();
          tipsPlugin.getTip(andels);
        },
        error: function (data) {
          $(`#tips__placeholder`).prepend(`<div class="alert alert-danger">${data.responseJSON.error}</div>`);
          andels.plugins.utils.removeSpinner();
        },
      });
    });
  },
  getTip : (andels) => {
    $('.tip').on('click', function () {
      const id = $(this).data('id');
      andels.plugins.utils.attacheSpinner();
      $.ajax({
        type: 'GET',
        url: `/tip/${id}`,
        success: function (data) {
          $('#tipModal .modal-body .title').html(data.title);
          $('#tipModal .modal-body .body').html(data.description);
          $('#hide_tips--container').hide();
          $('#nextTip').removeClass('d-flex').css('display', 'none');
          andels.plugins.utils.removeSpinner();
          $('#tipModal').modal('show');
        },
        error: function (data) {
          $(`#tips__placeholder`).prepend(`<div class="alert alert-danger">${data.responseJSON.error}</div>`);
          andels.plugins.utils.removeSpinner();
        },
      });
    });
  }
};
module.exports = tipsPlugin;
