const chatPlugin = {
    key: 'chat',

    pluginBehavior: (andels, $) => {
        chatPlugin.attacheManage();
        chatPlugin.attacheUpdate();
        chatPlugin.attacheDestroy();
        chatPlugin.chatLists();
        chatPlugin.removeFromChat();
        $.extend($.expr[":"], {
            "containsIN": function (elem, i, match, array) {
                return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
            }
        });

        $('.chat-form').find('textarea').on('keypress', function (event) {
            if (event.keyCode === 13 && $(this).val().replace(/(\r\n|\n|\r)/gm, "").length > 0) {
                chatPlugin.storeNotification();
            }
        });
        $("#messages_search").on('keyup', function () {
            let value = $(this).val();
            let show = false;
            if (value.length > 3) {
                $('#messages__results--placeholder').html('');
                $(`.member__name div:containsIN(${value})`).each(function () {
                    $('#messages__results--placeholder').append(chatPlugin.preExistedTemplate($(this)));
                    show = true;
                });
                if (show) {
                    chatPlugin.attachActive();
                    $('#messages__results--placeholder').show();
                }
                chatPlugin.ajaxMessageCall($(this), $('#messages__results--placeholder'), 'messages', '/api/message/user', andels);
            }
        });

    },
    clearSearch: (element) => {
        $('#messages_search').on('focusout', function () {
            $('#messages_search').val('');
            andels.plugins.invite.clearPlaceholder('messages');
        });
    },
    preExistedTemplate: (element) => {
        const id = element.parents('li').data('id');
        if (!$(`.existed[data-id=${id}]`).length) {
            return `<div data-id="${id}" class="existed link--blue cursorPointer mb-1">${element.html()}</div>`;
        }
        return '';
    },
    attachActive: () => {
        $('.existed').on('click', function () {
            const id = $(this).data('id');
            andels.plugins.invite.clearPlaceholder('messages');
            $(`.member[data-id=${id}]`).trigger('click');
        });
    },
    preImgTemplate: (key, value, type, img) => {
        if (!$(`#participants .member[data-id=${key}]`).length) {
            return `<div class="confirm__search cursorPointer mb-1" data-img="${img}" data-type="${type}" data-id="${key}">${value}</div>`;
        }
        return '';
    },
    ajaxMessageCall: (query, element, type, url, andels) => {
        let data = $('#invitationForm').serializeArray();
        data.push({name: "query", value: query.val()});
        $.ajax({
            type: 'GET',
            url: url,
            data: data,
            success: function (data) {
                if (data.data) {
                    $.each(data.data, (key, value) => {
                        element.append(chatPlugin.preImgTemplate(key, value, type, data.imgs[key]));
                    });
                    element.show();
                    chatPlugin.addToMessages(andels);
                }
            },
            error: function (data) {
                $('#search__results--placeholder').prepend(`<div class="alert alert-warning">${data.responseJSON.data}</div>`);
            },
        });
    },
    templateUser: (key, img, value) => {
        return `<li class="member active" data-id="${key}">
            <span>
              <div class="circle-35">
                <img src="${img}">
              </div>
            </span>
              <div class="ml-2 member__name">
                <div>${value}</div>
              </div>
              <div class="ml-2 member__name--special ">
                <div>${value}</div>
              </div>
          </li>`;
    },
    addToMessages: (andels) => {
        $('#messages__results--placeholder .confirm__search').on('click', function () {
            $('.messages__participants--container li').removeClass('active');
            $('.messages__list').html('');
            $('.messages__participants--list').prepend(chatPlugin.templateUser($(this).data('id'), $(this).data('img'), $(this).html()));
            $('#recipient').val($(this).data('id'));
            $('#messages_search').val('');
            andels.plugins.invite.clearPlaceholder('messages');
            const href = `/user/${$(this).data('id')}-` + encodeURIComponent($(this).html())
            $('#chatinfo').html(`<li class="member" data-id="${$(this).data('id')}"><a href="${href}">
        <div class="circle-24"><img src="${$(this).data('img')}" /></div><span class="pl-3">${$(this).html()}</span>
        </a></li>`);
            andels.plugins.notifications.clickOnMember($(`.member[data-id=${$(this).data('id')}]`), andels);
        });
    },
    removeFromChat: () => {
        $('.remove-from-chat').on('click', function () {
            const user = $(this).data('target');
            const id = $('#chat_id').val();
            if (user && id) {
                $('#chatinfo').find(`.member[data-id="${user}"]`).remove();
                chatPlugin.ajaxCall(`/chat/remove/${user}/${id}`, 'POST', {});
            }
        });
    },
    chatLists: () => {
        if ($('#participants').length && $('#chatinfo').length) {
            window.sortable.Sortable.create(document.getElementById('participants'), {
                group: {
                    name: 'shared',
                    pull: 'clone',
                    put: false
                },
                animation: 150,
                sort: false,
            });
            window.sortable.Sortable.create(document.getElementById('chatinfo'), {
                group: 'shared',
                animation: 150,
                onAdd: function (/**Event*/evt) {
                    let itemEl = evt.item;
                    const id = itemEl.dataset['id'];
                    const chat = $('#chat_id').val();

                    if(id.indexOf('business_chat') >= 0) {
                        return;
                    }

                    if (id && chat) {
                        $.ajax({
                            type: 'POST',
                            url: `/chat_member/${chat}/${id}/add`,
                            success: function (data) {
                                $('#chatinfo').html(data.html);
                            },
                            error: function (data) {
                                $(id).modal('hide');
                                $(`.messages__list`).prepend(`<div class="alert alert-danger">${data.responseJSON.error}</div>`);
                            },
                        });
                    } else {
                        let members = new Array();
                        let isBusinessChat = false;
                        $('#chatinfo .member').each(function (key, element) {
                            if (element.dataset['id'].toString().indexOf('business_chat_') >= 0) {
                                isBusinessChat = true;
                            }
                            members.push(element.dataset['id']);
                        });

                        if (!isBusinessChat) {
                            $('#manageChatForm #add_members').val(members.toString());
                            $('#manageChatForm').modal('show');
                        }
                    }
                },
            });
        }
    },
    storeNotification: () => {
        $.ajax({
            type: 'POST',
            url: `/chat_message/store`,
            data: $('.chat-form').serialize(),
            success(data) {
                $('.messages__list').prepend(data);
                $('.chat-form').find('textarea').val('');
                $('.chat-form').find('.alert.alert-danger').remove();
            },
            error(data) {
                $('.messages__list').html(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
            }
        });
    },
    attacheManage: () => {
        $('.manage-chat').on('click', function () {
            const value = $('#manageChatForm #form_title').val();
            if (!value) {
                $('#manageChatForm #form_title').addClass('is-invalid');
            } else {
                const id = $('#manageChatForm #form_id').val();
                if (!id) {
                    chatPlugin.ajaxCall(`/chat/store`, 'POST', $('#chatForm').serialize(), '#manageChatForm');
                } else {
                    chatPlugin.ajaxCall(`/chat/update/${id}`, 'POST', $('#chatForm').serialize(), '#manageChatForm');
                }
            }
        });
        $('#manageChatForm').on('hide.bs.modal', function () {
            $('#chatForm input').val('');
            $('#chatinfo [draggable="false"]').remove();
        });
    },
    attacheUpdate: () => {
        $('.update--chat').on('click', function (e) {
            e.preventDefault();
            const id = $(this).data('id');
            const value = $(`.chat .title-${id}`).html();
            $('#manageChatForm #form_title').val(value);
            $('#manageChatForm #form_id').val(id);
            $('#manageChatForm').modal('show');
        });
    },
    attacheDestroy: () => {
        $('.destroy--chat').on('click', function (e) {
            e.preventDefault();
            const id = $(this).data('id');
            const value = $(`.chat .title-${id}`).html();
            $('#destroyChat').attr('action', `/chat/destroy/${id}`)
            $('#deleteModal .modal-body strong').html(value);
            $('#deleteModal').modal('show');
        });
    },
    ajaxCall: (url, method, data, id = '') => {
        $.ajax({
            type: method,
            url: url,
            data: data,
            success: function (data) {
                if (data.href) {
                    window.location.href = data.href;
                } else {
                    window.location.reload();
                }
            },
            error: function (data) {
                if (id) {
                    $(id).modal('hide');
                }
                $(`.messages__list`).prepend(`<div class="alert alert-danger">${data.responseJSON.error}</div>`);
            },
        });
    },
};
module.exports = chatPlugin;
