const commentsPlugin = {
    key: 'comments',

    pluginBehavior: (andels, $) => {
        if ($('#comments-area').length) {
            $('.item_content .comments-scroll').on('click', function () {
                andels.plugins.utils.scrollTo($('#comments-area'));
            });
        }

        if ($('.comment_create').length) {
            $.each($('[id^="comment_create-"]'), function () {
                window.attachEnteredSimpleEditor($(this).find('textarea'));
            });
        }

        commentsPlugin.toggleNotifyTitle();
        commentsPlugin.updateCommentNotification();
        commentsPlugin.showComments();
        commentsPlugin.showChildren();
        commentsPlugin.setBestAnswer(andels);
        commentsPlugin.attachCommentUpdate();
        commentsPlugin.attachCommentDelete();
        commentsPlugin.attachCommentReply();
        commentsPlugin.handleCommentForm();
        commentsPlugin.attachAuthoredComment();
    },
    attachCommentUpdate: () => {
        $(document).on('click', '.comment-update', function () {
            const id = $(this).data('target');
            $.ajax({
                type: 'GET',
                url: '/get/form',
                data: {comment: id},
                success: function (data) {
                    $(`#comment-${id}--content`).hide();
                    $(`#comment-${id}--placeholder`).html(data.html);
                    window.attachEnteredSimpleEditor($(`#comment-${id}--placeholder textarea`));
                    $('.undo-edit').click(function () {
                        $(`#comment-${id}--content`).show();
                        $(`#comment-${id}--placeholder`).html('');
                    });
                    commentsPlugin.toggleNotifyTitle();
                },
                error: function (data) {
                    $(`#comment-${id}--placeholder`).html(`<div class="alert alert-danger">${data.responseJSON.data}</div>`);
                },
            });
        });
    },
    attachCommentDelete: () => {
        $(document).on('click', '.comment-delete', function (e) {
            e.preventDefault();
            const id = $(this).data('id');
            $(`#comment-delete-form-${id}`).submit();
        });
    },
    attachCommentReply: () => {
        $(document).on('click', '.comment-reply', function () {
            const id = $(this).data('target');
            $.ajax({
                type: 'GET',
                url: '/get/form',
                data: {parent: id},
                success: function (data) {
                    $(`#comment-${id}--placeholder`).html(data.html);
                    window.attachEnteredSimpleEditor($(`#comment-${id}--placeholder textarea`));
                    $('.undo-reply').click(function () {
                        $(`#comment-${id}--placeholder`).html('');
                    });
                    commentsPlugin.toggleNotifyTitle();
                },
                error: function (data) {
                    $(`#comment-${id}--placeholder`).html(`<div class="alert alert-danger">${data.responseJSON.data}</div>`);
                },
            });
        });
    },
    updateCommentNotification: () => {
        $(document).on('click', '.comment-notify', function () {
            if ($(this).data('comment') !== undefined) {
                const notify = ($(this).is(':checked')) ? 1 : 0;
                $.ajax({
                    type: 'POST',
                    url: '/comment/notify',
                    data: {id: $(this).data('comment'), notify: notify},
                });
            }
        });
    },
    toggleNotifyTitle: () => {
        $(document).on('click', '[id^=notify]', function () {
            let title = window.translator.trans('comments.not_notify');
            if ($(this).is(':checked')) {
                title = window.translator.trans('comments.notify');
            }
            $(this).next('label').attr('title', title);
        });
    },
    showComments: () => {
        $(document).on('click', '.post .show-comments', function () {
            const id = $(this).parents('.post').data('id');
            andels.plugins.utils.trackUmamiEvent('Open comments', {post_id: id})
            $(`.comments-${id}`).toggle();
        });
    },
    showChildren: () => {
        $(document).on('click', '.show-children', function () {
            $(`.comment-child[data-id="${$(this).data('id')}"]`).toggle();
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
            } else {
                $(this).addClass('open');
            }
        });
    },
    setBestAnswer: (andels) => {
        $(document).on('click', '.best--answer', function () {
            const comment = $(this).data('comment');
            let set = $(this).is(':checked') ? 1 : 0;
            andels.plugins.utils.attacheSpinner();
            $.ajax({
                type: 'POST',
                url: '/best/answer',
                data: {id: $('#best_id').val(), comment: comment, set: set, type: $('#best_type').val()},
                success: function () {
                    andels.plugins.utils.removeSpinner();
                    window.location.reload();
                },
                error: function () {
                    andels.plugins.utils.removeSpinner();
                    window.location.reload();
                }
            });
        });
    },
    handleCommentForm: () => {
        $(document).on('submit', 'form.comment_create, form.comment_update, form.comment_reply', function (e) {
            e.preventDefault();
            const form = $(this);
            andels.plugins.utils.attacheSpinner();

            $.ajax({
                type: "POST",
                url: form.attr('action'),
                data: form.serialize(), // serializes the form's elements.
                success: function (res) {
                    if (res.html !== undefined && res.post_id !== undefined) {
                        andels.plugins.utils.trackUmamiEvent('Create a comment', {post_id: res.post_id})

                        $(`.comments-${res.post_id}`).html(res.html);
                        window.attachEnteredSimpleEditor($(`#comment_create-${res.post_id} textarea`));
                    }
                    andels.plugins.utils.removeSpinner();
                },
            });
        });
    },
    attachAuthoredComment: () => {
        $(document).on('click', '.store-authored-comment', function () {
            const form = $(this).closest('form.comment_create, form.comment_reply');

            const author_id = $(this).data('author-id');
            const author_type = $(this).data('author-type');

            form.find('[name="author_type"]').first().val('');
            form.find('[name="author_id"]').first().val('');
            if (author_id !== undefined && author_type !== undefined) {
                form.find('[name="author_type"]').first().val(author_type);
                form.find('[name="author_id"]').first().val(author_id);
            }

            andels.plugins.utils.trackUmamiEvent('Create a comment')

            form.submit();
        });
    },
};
module.exports = commentsPlugin;
