const dawaAutocomplete = require("dawa-autocomplete2");
const dawaPlugin = {

    key: 'dawa',

    pluginBehavior: (andels, $) => {
        const dawaAutocomplete = require('dawa-autocomplete2');

        if (document.getElementById("dawa_address")) {
            dawaAutocomplete.dawaAutocomplete(document.getElementById("dawa_address"), {
                select: function (selected) {
                    if (selected.data.id) {
                        $('#address_point_id').val(selected.data.id);
                        $('#address_id').val(selected.data.adgangsadresseid);
                    }
                },
            });

            $('#dawa_address').focusout(() => {
                checkAddress();
            });

            $('#dawa_address').on('click', function () {
                $(this).val('');
            });

            $('#registration').submit(() => {
                return checkAddress();
            });
        }

        if ($('#dawa_address_corporation').length) {
            dawaAutocomplete.dawaAutocomplete(document.getElementById("dawa_address_corporation"), {
                adgangsadresserOnly: true,
                select: function (selected) {
                    const lastId = $('#lastId').val();
                    $('#lastId').val(parseInt(lastId) + 1);
                    $('#dawa_address_corporation_results').append(
                        `<div class="dawa-address">
              <input type='radio' class='styled-radio' name='primary' id="primary-${lastId}" value="${lastId}"/>
              <label for="primary-${lastId}">${selected.tekst}</label><i data-id="${selected.data.id}" class="clearAddress text-danger fa fa-trash"></i>
            </div>`
                    );
                    clearAddress();
                    $('#dawa_corporation_input_results').append(`<input type="hidden" id="${selected.data.id}" name="selectedAddresses[${lastId}]" value='${JSON.stringify(selected.data)}' />`);
                },
            });
        }

        if ($('#dawa_address_corporation_creation').length) {
            dawaAutocomplete.dawaAutocomplete(document.getElementById("dawa_address_corporation_creation"), {
                adgangsadresserOnly: true,
                select: function (selected) {
                    const lastId = $('#lastAddressId').val();
                    $('#lastAddressId').val(parseInt(lastId) + 1);
                    $('#dawa_address_corporation_creation_results').append(
                        `<li class="dawa-address py-1 border-bottom border-secondary my-2 d-flex align-items-center justify-content-between">
                            <span>${selected.tekst}</span>&nbsp;
                            <i data-id="${selected.data.id}" class="clearAddress text-danger cursorPointer far fa-trash-alt"></i>
                        </li>`
                    );
                    clearNewCorporationAddress();
                    $('#dawa_corporation_creation_input_results').append(`<input type="hidden" id="${selected.data.id}" name="selectedAddresses[${lastId}]" value='${JSON.stringify(selected.data)}' />`);
                    andels.plugins.auth.processNewCorporationAddresses();
                },
            });
        }

        if ($('#dawa_selfservice_address').length) {
            dawaAutocomplete.dawaAutocomplete(document.getElementById("dawa_selfservice_address"), {
                select: function (selected) {
                    if (selected.data.id) {
                        $('#address_id').val(selected.data.id);
                    }
                },
            });
        }

        if ($('#dawa_termination_address').length) {
            dawaAutocomplete.dawaAutocomplete(document.getElementById("dawa_termination_address"), {
                select: function (selected) {
                    if (selected.data.id) {
                        const addressId = selected.data.id;
                        $('#address_id').val(addressId);
                        andels.plugins.selfservice.validateField('address_id', addressId, $('#dawa_termination_address'), $('#dawa_termination_address').siblings('.invalid-feedback'));
                    }
                },
            });
        }

        if ($('#dawa_address_business').length) {
            dawaAutocomplete.dawaAutocomplete(document.getElementById("dawa_address_business"), {
                adgangsadresserOnly: true,
                select: function (selected) {
                    $('#business_address').val(selected.data.id);
                },
            });
        }

        $('.removeAddress').on('click', function () {
            const id = $(this).data('id');
            if ($(this).parent('.dawa-address').hasClass('removed')) {
                $(this).parent('.dawa-address').removeClass('removed');
                $(`#remove${id}`).remove();
            } else {
                $(this).parent('.dawa-address').addClass('removed');
                $('#dawa_corporation_input_results').append(`<input type="hidden" id="remove${id}" name="removeAddresses[]" value='${id}' />`);
            }
        });

        function checkAddress() {
            if ($('#dawa_address').val().length && $('#address_point_id').val().length === 0) {
                $('.address-error').show();
                $('#dawa_address').addClass('is-invalid');
                return false;
            } else {
                $('.address-error').hide();
                $('#dawa_address').removeClass('is-invalid');
                return true;
            }
        }

        function clearAddress() {
            $('.clearAddress').on('click', function () {
                const id = $(this).data('id');
                $(this).parent('.dawa-address').remove();
                $(`#${id}`).remove();
            });
        }

        function clearNewCorporationAddress() {
            $('.clearAddress').on('click', function () {
                const id = $(this).data('id');
                $(this).parent('.dawa-address').remove();
                $(`#${id}`).remove();
                andels.plugins.auth.processNewCorporationAddresses();
            });
        }
    }
};

module.exports = dawaPlugin;
