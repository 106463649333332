const searchPlugin = {
    key: 'search',

    pluginBehavior: (andels, $) => {
        searchPlugin.selectChange();
        $('[name="search"]').on("keypress", function (event) {
            const keycode = (event.keyCode ? event.keyCode : event.which);
            if (keycode == '13' && $(this).val().length) {
                $(this).parents('form').submit();
            }
        });
        $('#nearby').on('click', function () {
            $(this).parents('form').submit();
        });

        searchPlugin.initListFilters();
        searchPlugin.attachDocumentsSearch();
        searchPlugin.attachAjaxPagination();
    },
    selectChange: () => {
        $('.marketplace__select--container select').on('change', function () {
            $(this).parents('form').submit();
        });
    },
    initListFilters: () => {
        if ($('#listSearch').length) {
            let timer;
            $('#listSearch').on('input', function () {
                const term = $(this).val();
                const orderBy = $('#orderBy').val();

                if (term.length > 2 || term.length === 0) {
                    clearTimeout(timer);
                    const delay = 500;
                    timer = setTimeout(function () {
                        searchPlugin.applyFilters(term, orderBy);
                    }, delay);
                }
            });
        }

        if ($('#orderBy').length) {
            $('#orderBy').on('change', function () {
                const orderBy = $(this).val();
                const term = $('#listSearch').val().length > 2 ? $('#listSearch').val() : '';

                searchPlugin.applyFilters(term, orderBy);
            });
        }

    },
    applyFilters: (searchTerm, orderBy) => {
        const entityName = $('#entity').val();
        const route = searchPlugin.getEntityFilterRoute(entityName);

        if (route) {
            andels.plugins.utils.attacheSpinner();
            $.ajax({
                url: route,
                data: {
                    term: searchTerm,
                    orderBy: orderBy,
                },
                success: function (result) {
                    $('#itemsList').html(result.html);
                    $('#pageN').val(0);
                    $(window).off('scroll.list');

                    switch (entityName) {
                        case 'group':
                            andels.plugins.groups.initAjaxPagination();
                            break;

                        case 'organization':
                            andels.plugins.organizations.initAjaxPagination();
                            break;

                        case 'business':
                            andels.plugins.business.initAjaxPagination();
                            break;
                    }
                },
                complete: function () {
                    andels.plugins.utils.removeSpinner();
                }
            });
        }
    },
    getEntityFilterRoute: (entityName) => {
        let route = null;

        switch (entityName) {
            case 'group':
                route = '/filter/groups';
                break;

            case 'organization':
                route = '/filter/organizations';
                break;

            case 'business':
                route = '/filter/businesses'
        }

        return route;
    },
    attachDocumentsSearch: () => {
        if ($('#documentSearch').length) {
            let timer;

            $('#documentSearch').on('input', function () {
                const term = $(this).val();
                const resultContainer = $(this).siblings('.documents-tools__search-result');

                if (term.length > 1) {
                    clearTimeout(timer);
                    const delay = 500;
                    timer = setTimeout(function () {
                        andels.plugins.utils.attacheSpinner();
                        $.ajax({
                            url: '/api/filesearch',
                            data: {
                                term: term,
                            },
                            success: function (result) {
                                resultContainer.html(result.html);
                            },
                            complete: function () {
                                andels.plugins.utils.removeSpinner();
                            }
                        });
                    }, delay);
                } else if (term.length === 0) {
                    resultContainer.html('');
                }
            });
        }
    },
    attachAjaxPagination: () => {
        if ($('#marketplaceList').length) {
            $(window).on('scroll.list', function () {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    const pageNumberInput = $('#pageN');
                    const currentPageNumber = parseInt(pageNumberInput.val());
                    const urlParams = new URLSearchParams(window.location.search);
                    const paramsObject = {};

                    urlParams.forEach((value, key) => {
                        paramsObject[key] = value;
                    });

                    paramsObject.page = currentPageNumber + 1;

                    andels.plugins.utils.attacheSpinner();
                    $.ajax({
                        url: window.location.pathname,
                        data: paramsObject,
                        success: function (result) {
                            const items = $(result).find('#marketplaceList').html();
                            const cnt = $(result).find('.marketplace__list--item').length;

                            if (cnt > 0) {
                                $('#marketplaceList').append(items);
                                pageNumberInput.val(currentPageNumber + 1);
                            } else {
                                $(window).off('scroll.list');
                            }
                        },
                        complete: function () {
                            andels.plugins.utils.removeSpinner();
                        }
                    });
                }
            });
        }
    }
};
module.exports = searchPlugin;
