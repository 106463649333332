const facilityPlugin = {
    key: 'facility',
    dropZone: 0,

    pluginBehavior: (andels, $) => {
        facilityPlugin.attachCreateModal();
        facilityPlugin.attachUpdateModal();
        facilityPlugin.attacheUpdate();
        facilityPlugin.attachCreate();
        facilityPlugin.attacheFolderCreate();
        facilityPlugin.attacheFolderUpdate();
        facilityPlugin.facilityShare();
        facilityPlugin.attacheFacilityForm();
        facilityPlugin.attacheGroupEventFacilityBookingCreate();
        facilityPlugin.attacheGroupEventFacilityBookingEdit();
        facilityPlugin.attachOrganizationCreateBookingForm();
        facilityPlugin.attachOrganizationEditBookingForm();
        setTimeout(facilityPlugin.attacheFacilityCheck, 500);

        $('#recurring').on('click', function () {
            $('[data-toggle="recurring"]').toggle();
        });

        $('.folder-upload').on('click', function () {
            const id = $(this).data('id');
            $('#filesUpload').find('#folder').val(id);
            $('#filesUpload').find('#type').val($(this).data('priv'));
            if (!facilityPlugin.dropZone) {
                facilityPlugin.dropZone = andels.plugins.utils.attacheDropzone(
                    "#filesUpload #dropzone",
                    andels.plugins.utils.filesDropzone(`/board/upload/${id}`, $('#filesUpload'))
                );
            }
            $('#uploadConfirmation').modal('show');
            $('.files-upload').on('click', function () {
                if (facilityPlugin.dropZone) {
                    facilityPlugin.dropZone.processQueue();
                }
            });
            $('#uploadConfirmation').on('hide.bs.modal', function () {
                $('#filesUpload').find('#type').val('');
                $('#filesUpload').find('#folder').val('');
            });
        });
        $('.folder-update').on('click', function () {
            const id = $(this).data('id');
            $(`#updateForm #form-placeholder`).val($(`.update-${id}`).html());
            if ($(this).data('parent')) {
                $(`#updateForm select`).find(`option[value="${$(this).data('parent')}"]`).attr("selected", true);
            } else {
                $(`#updateForm select`).append('<option selected value=0></option>');
                $(`#updateForm .select-group`).hide();
            }
            $('#updateForm').find('.folder-send-update').data('id', id);
            $('#updateForm').modal('show');
        });

    },
    /** Facility CRUD Start **/
    facilityShare: () => {
        $('.facility-share').on('click', function () {
            const id = $(this).data('id');
            $('#shareForm').find('#facility_id').val(id);
            $('#shareFacility').modal('show');
        });
        $('#shareFacility').on('hide.bs.modal', function () {
            $('#shareForm').find('#facility_id').val('');
            $('#shareForm').find('#search__results--placeholder').html('');
            $('#shareForm').find('#corporation__results--placeholder').html('');
        });
    },
    attachCreateModal: () => {
        const modal = $('#facilityCreateForm');

        $('.create-facility').on('click', function () {
            const owner_id = $(this).data('owner-id');
            const owner_type = $(this).data('owner-type');

            $.ajax({
                url: '/facility/create',
                data: {owner_id, owner_type},
                success: function (result) {
                    modal.find('.modal-body').html(result.html);
                    modal.modal('show');
                },
            });
        });

        modal.on('hide.bs.modal', function () {
            modal.find('.modal-body').html('');
        });
    },
    attachUpdateModal: () => {
        const modal = $('#facilityUpdateForm');

        $('.facility-update').on('click', function () {
            const id = $(this).data('id');

            $.ajax({
                url: `/facility/${id}/edit`,
                success: function (result) {
                    modal.find('.modal-body').html(result.html);
                    modal.find(`#color-placeholder option[value="${result.color}"]`).attr('selected', 'selected');
                    modal.find('.circle-24').css('background-color', result.color);
                    modal.modal('show');
                },
            });
        });

        modal.on('hide.bs.modal', function () {
            modal.find('.modal-body').html('');
        });
    },
    attachCreate: () => {
        $('.facility-create').on('click', function () {
            const title = $(`#facilityCreateForm [name="name"]`).val();
            const description = $(`#facilityCreateForm [name="description"]`).val();

            if (!title) {
                $(`#facilityCreateForm [name="name"]`).addClass('is-invalid');
            } else if (!description) {
                $(`#facilityCreateForm [name="description"]`).addClass('is-invalid');
            } else {
                $(this).hide();
                $.ajax({
                    type: 'POST',
                    url: '/facility',
                    data: new FormData(document.getElementById('facilityCreate')),
                    processData: false,
                    dataType: "JSON",
                    contentType: false,
                    success: function () {
                        andels.plugins.utils.trackUmamiEvent('Create a facility')

                        window.location.reload();
                    },
                    error: function (data) {
                        $('#facilityCreateForm').modal('hide');
                        $(`#calendar`).prepend(`<div class="alert alert-danger">${data.responseJSON.error}</div>`);
                    },
                });
            }
        });
    },
    attacheUpdate: () => {
        $('.facility-send-update').on('click', function () {
            const title = $(`#facilityUpdateForm [name="name"]`).val();
            const description = $(`#facilityUpdateForm [name="description"]`).val();

            if (!title) {
                $(`#facilityUpdateForm [name="name"]`).addClass('is-invalid');
            } else if (!description) {
                $(`#facilityUpdateForm [name="description"]`).addClass('is-invalid');
            } else {
                andels.plugins.utils.trackUmamiEvent('Update a facility')
                $('#facilityUpdate').submit();
            }
        });
    },
    /** Facility CRUD end **/

    attacheFolderCreate: () => {
        $('.folder-create').on('click', function () {
            const value = $(`#folderCreate #form-placeholder`).val();
            if (!value) {
                $(`#folderCreate #form-placeholder`).addClass('is-invalid');
            } else {
                facilityPlugin.ajaxCall(`/board/folder`, 'POST', $('#folderCreate').serialize(), '#createForm');
            }
        });
        $('#createForm').on('hide.bs.modal', function () {
            $('#createForm').find('#form-placeholder').val('');
            $('#createForm').find('.folder-create').data('id', '');
        });
    },
    attacheFolderUpdate: () => {
        $('.folder-send-update').on('click', function () {
            const value = $(`#updateForm #form-placeholder`).val();
            const id = $(this).data('id');
            if (!value) {
                $(`#updateForm #form-placeholder`).addClass('is-invalid');
            } else {
                facilityPlugin.ajaxCall(`/board/folder/${id}`, 'PUT', $('#folderUpdate').serialize(), '#updateForm');
            }
        });
        $('#updateForm').on('hide.bs.modal', function () {
            $('#updateForm').find('#form-placeholder').val('');
            $('#updateForm').find('.folder-send-update').data('id', '');
            $(`#updateForm select`).find('option[value=0]').remove();
            $(`#updateForm .select-group`).show();
        });
    },

    /** Facility booking start **/
    attacheFacilityForm: () => {
        $('.facility-book').on('click', function () {
            let valid = true;
            $.each(['#from_date', '#to_date', '#time_to', '#time_from'], function (index, value) {
                if (!$('#bookFacility').find(value).val()) {
                    valid = false;
                    $('#bookFacility').find(value).addClass('is-invalid');
                }
            });
            if ($('#recurring').is(':checked') && !$('[name="recurring_type"]').is(':checked')) {
                $('[name="recurring_type"]').addClass('is-invalid');
                valid = false;
            }
            if (valid) {
                $.ajax({
                    url: '/booking/verify',
                    data: $('#bookFacilityForm').serialize(),
                    success: function () {
                        andels.plugins.utils.trackUmamiEvent('Book a facility')

                        $('#bookFacilityForm').submit();
                    },
                    error: function (data) {
                        $('#bookFacility .error').html(data.responseJSON.error);
                        $('#bookFacility .error').show();
                    }
                });

            }

        });
        $('.closeDescription').on('click', function () {
            $('#bookingDetails').hide();
            $('#bookingDetails .content').html('');
        });
    },
    attacheGroupEventFacilityBookingCreate: () => {
        $('.facility-booking-create').on('click', function () {
            facilityPlugin.buildCreateBookingForm(null, null, $(this).data('bookable-id'), $(this).data('bookable-type'), $(this).data('facility-id'));
        });
    },
    attacheGroupEventFacilityBookingEdit: () => {
        $('.facility-booking-edit').on('click', function () {
            const booking_id = $(this).data('id');
            if (booking_id) {
                facilityPlugin.buildEditBookingForm(booking_id);
            }
        });
    },
    attacheFacilityCheck: () => {
        $(document).on('change', '#bookFacility #facility, #bookFacility #recurring, #bookFacility [name="recurring_type"]', function () {
            let modalOpened = $('#bookFacility').hasClass('show');
            let errorEl = $('#bookFacility .error');
            let form = $('#bookFacility form');

            if (facilityPlugin.facilityFormIsFilled(form) && modalOpened) {
                facilityPlugin.checkFacilityAvailable(form, errorEl);
            }
        });

        $(document).on(
            "dp.change",
            "#bookFacility #from_date, #bookFacility #to_date, #bookFacility #time_from, #bookFacility #time_to",
            function (e) {
                let modalOpened = $('#bookFacility').hasClass('show');
                let errorEl = $('#bookFacility .error');
                let form = $('#bookFacility form');

                if (facilityPlugin.facilityFormIsFilled(form) && modalOpened) {
                    facilityPlugin.checkFacilityAvailable(form, errorEl);
                }
            });
    },
    buildCreateBookingForm: (owner_id = null, owner_type = null, bookable_id = null, bookable_type = null, facility_id = null) => {
        return $.ajax({
            type: 'GET',
            url: `/booking/create`,
            data: {owner_id, owner_type, bookable_id, bookable_type, facility_id},
            success: function (data) {
                $('#bookFacility .modal-body').html(data.html);

                andels.plugins.utils.calendarDatePicker('from_date', 'to_date');
                andels.plugins.utils.calendarTimePicker('time_from', 'time_to');

                if ($('#destroyBooking').length) {
                    $('#destroyBooking').parent().remove();
                }

                $('#bookFacility').modal('show');
                $('#recurring').on('click', function () {
                    $('[data-toggle="recurring"]').toggle();
                });
            },
        });
    },
    buildEditBookingForm: (booking_id) => {
        return $.ajax({
            type: 'GET',
            url: `/booking/${booking_id}/edit`,
            success: function (data) {
                $('#bookFacility .modal-body').html(data.html);

                andels.plugins.utils.calendarDatePicker('from_date', 'to_date');
                andels.plugins.utils.calendarTimePicker('time_from', 'time_to');

                if (!$('#destroyBooking').length) {
                    const btn = window.translator.trans('form.delete');
                    $('#bookFacility .modal-footer')
                        .prepend(`<div class="mr-4 pr-4 border-right destroy__container">
                  <button type="button" class="btn btn-danger" id="destroyBooking">${btn}</button></div>`);
                }
                $('#bookFacility').modal('show');
                $('#recurring').on('click', function () {
                    $('[data-toggle="recurring"]').toggle();
                });
                $('#destroyBooking').on('click', function () {
                    $('#deleteBookingConfirmation #booking').val(booking_id);
                    $('#bookFacility').modal('hide');
                    $('#deleteBookingConfirmation').modal('show');
                });
            },
        });
    },
    attachOrganizationCreateBookingForm: () => {
        $('.org-facility-book').on('click', function () {
            const facility_id = $(this).data('id');
            facilityPlugin.buildCreateBookingForm(null, null, null, null, facility_id);
        });
    },
    attachOrganizationEditBookingForm: () => {
        $('.org-facility-book-edit').on('click', function () {
            let booking_id = $(this).data('id');
            if (booking_id) {
                facilityPlugin.buildEditBookingForm(booking_id);
            }
        });
    },
    checkFacilityAvailable: (form, errorBox) => {
        $.ajax({
            url: '/booking/verify',
            data: form.serialize(),
            success: function () {
                errorBox.hide();
            },
            error: function (data) {
                errorBox.html(data.responseJSON.error);
                errorBox.show();
            }
        });
    },
    facilityFormIsFilled: (form) => {
        return form.find('#facility').val() && form.find('#from_date').val() && form.find('#to_date').val() && form.find('#time_from').val() && form.find('#time_to').val()
    },

    /** Facility booking end **/

    ajaxCall: (url, method, data, id) => {
        $.ajax({
            type: method,
            url: url,
            data: data,
            success: function () {
                window.location.reload();
            },
            error: function (data) {
                $(id).modal('hide');
                $(`#calendar`).prepend(`<div class="alert alert-danger">${data.responseJSON.error}</div>`);
            },
        });
    },
};
module.exports = facilityPlugin;
