const selfservicePlugin = {
    key: 'selfservice',

    pluginBehavior: (andels, $) => {
        selfservicePlugin.attachPersonalDataHandlers();
        selfservicePlugin.initPaymentSection();
        selfservicePlugin.selectSubscription();
        selfservicePlugin.selectAddon();
        selfservicePlugin.initTerminationSection();
    },
    attachPersonalDataHandlers: () => {
        if ($('.phone.selfservice-phone').length) {
            selfservicePlugin.maskPhone($('.phone.selfservice-phone'));
            $('.phone.selfservice-phone').on('keyup', function () {
                selfservicePlugin.maskPhone($(this));
            });
        }

        if ($('.selfservice-personal-field').length) {
            $('.selfservice-personal-field').each(function (index, field) {
                $(field).data('original-value', $(field).val())
            });

            $('.selfservice-personal-field').on('keyup', function () {
                let allowStore = false;

                $('.selfservice-personal-field').each(function (index, field) {
                    if ($(field).val() !== $(field).data('original-value')) {
                        allowStore = true;
                    }
                });

                if (allowStore) {
                    $('#selfservicePersonalDataSubmit').show();
                } else {
                    $('#selfservicePersonalDataSubmit').hide();
                }
            });
        }
    },
    initPaymentSection: () => {
        const paymentForm = $('#selfservicePaymentActivateForm');
        const noCompanyField = $('#payment_no_company');
        const vatField = $('#payment_vat');
        const nameField = $('#payment_name');
        const addressField = $('#dawa_selfservice_address');
        const addressIdField = $('#address_id');
        const emailField = $('#payment_email');
        const phoneField = $('#payment_phone');
        const eanField = $('#payment_ean');

        $('#selfservicePaymentActivate').on('change', function () {
            $(this).is(':checked') ? paymentForm.hide() : paymentForm.show();
        });

        $('#selfservicePaymentDeactivate').on('change', function () {
            if ($(this).is(':checked')) {
                $('#deletePaymentModal').modal('show')
            }
        });

        $('#deletePaymentModal').on('hidden.bs.modal', function () {
            $('#selfservicePaymentDeactivate').prop('checked', false);
        });

        if (noCompanyField.length) {
            if (noCompanyField.is(':checked')) {
                vatField.val('').attr('disabled', true).parent().hide();
                eanField.val('').attr('disabled', true).parent().hide();

                nameField.attr('disabled', false);
                addressField.attr('disabled', false);
                addressIdField.attr('disabled', false);
                emailField.attr('disabled', false);
                phoneField.attr('disabled', false);
            }

            noCompanyField.on('change', function () {
                const isNotCompany = $(this).is(':checked');

                if (isNotCompany) {
                    vatField.val('').attr('disabled', true).parent().hide();
                    eanField.val('').attr('disabled', true).parent().hide();

                    nameField.attr('disabled', false);
                    addressField.attr('disabled', false);
                    addressIdField.attr('disabled', false);
                    emailField.attr('disabled', false);
                    phoneField.attr('disabled', false);
                } else {
                    vatField.val('').attr('disabled', false).parent().show();

                    nameField.attr('disabled', true);
                    addressField.attr('disabled', true);
                    addressIdField.attr('disabled', true);
                    emailField.attr('disabled', true);
                    phoneField.attr('disabled', true);
                    eanField.val('').attr('disabled', true).parent().show();
                }
            });
        }

        if (vatField.length) {
            if (!noCompanyField.is(':checked')) {
                if (vatField.val().length === 8) {
                    nameField.attr('disabled', false);
                    emailField.attr('disabled', false);
                    phoneField.attr('disabled', false);
                    eanField.attr('disabled', false);
                    addressField.attr('disabled', false);
                    addressIdField.attr('disabled', false);
                } else {
                    nameField.attr('disabled', true);
                    emailField.attr('disabled', true);
                    phoneField.attr('disabled', true);
                    eanField.attr('disabled', true);
                    addressField.attr('disabled', true);
                    addressIdField.attr('disabled', true);
                }
            }

            vatField.on('keyup', function () {
                const paymentVatField = $(this);

                const value = paymentVatField.val();
                const valueSet = paymentVatField.data('set');

                if (value.length === 8) {
                    if (!valueSet) {
                        paymentVatField.data('set', true);

                        $.ajax({
                            type: 'GET',
                            url: `/selfservice/vat/${value}`,
                            success: function (result) {
                                if (nameField.val().length === 0) {
                                    nameField.val(result.name);
                                }

                                if (emailField.val().length === 0) {
                                    emailField.val(result.email);
                                }

                                if (phoneField.val().length === 0) {
                                    phoneField.val(result.phone);
                                }

                                if (addressField.val().length === 0) {
                                    addressField.val(result.address);
                                }


                                nameField.attr('disabled', false);
                                emailField.attr('disabled', false);
                                phoneField.attr('disabled', false);
                                eanField.attr('disabled', false);
                                addressField.attr('disabled', false);
                                addressIdField.attr('disabled', false);
                            },
                            error: function () {
                                paymentVatField.data('set', false);
                            }
                        });
                    }
                } else {
                    paymentVatField.data('set', false);
                    nameField.attr('disabled', true);
                    addressField.attr('disabled', true);
                    addressIdField.attr('disabled', true);
                    emailField.attr('disabled', true);
                    phoneField.attr('disabled', true);
                    eanField.attr('disabled', true);
                }
            });
        }
    },
    updateProductsSummary: () => {
        let subscriptionId = null;
        let addonidCnt = {};

        if ($('#selfserviceNewSubscriptionId').length > 0) {
            subscriptionId = parseInt($('#selfserviceNewSubscriptionId').val());
        }

        if ($('.selfservice-addons-input').length > 0) {
            $('.selfservice-addons-input').each(function (index, input) {
                let productConfId = $(input).data('product-conf-id');
                addonidCnt[productConfId] = $(input).val();
            });
        }

        window.andels.plugins.utils.attacheSpinner();
        $.ajax({
            type: 'GET',
            data: {subscriptionId: subscriptionId, addonidCnt: addonidCnt},
            url: `/selfservice/products-summary`,
            success: function (response) {
                if (response.success) {
                    $('#selfserviceProductsSummary').html(selfservicePlugin.getProductsSummaryHtml(response.result));
                    $('.selfservice-products-submit').show();
                    window.andels.plugins.utils.scrollTo('#selfserviceProductsSummary');
                }
            },
            error: function () {
            },
            complete: function () {
                window.andels.plugins.utils.removeSpinner();
            }
        });
    },
    getProductsSummaryHtml: (summaryData) => {
        let totalBuyPrice = '';
        if (summaryData.newSummary.hasOwnProperty('totalBuyPrice') && summaryData.newSummary.totalBuyPrice > 0) {
            totalBuyPrice = parseFloat(summaryData.newSummary.totalBuyPrice).toFixed(2).split('.').join(',');
        }

        let currentSummaryItems = '';
        let newSummaryItems = '';

        currentSummaryItems += `<li class="selfservice-products-summary-list__item selfservice-products-summary-list__item--title">${window.translator.trans('selfservice.products.old_products_prices')}</li>`;

        $.each(summaryData.currentSummary.products, function (index, value) {
            let price = parseFloat(value.price).toFixed(2).split('.').join(',');
            let collective = value.hasOwnProperty('collective') && value.collective;
            let note = '';
            let type = value.hasOwnProperty('type') ? value.type : 'subscription';

            if (collective) {
                currentSummaryItems += `<li class="selfservice-products-summary-list__item">
                                            <div class="selfservice-products-summary-list__price">
                                                <span>${value.quantity}x ${value.name}</span>
                                                <span>${window.translator.trans('selfservice.products.paid_via_rent')}</span>
                                            </div>
                                            <div class="selfservice-products-summary-list__note">${note}</div>
                                        </li>`;
            } else {
                currentSummaryItems += `<li class="selfservice-products-summary-list__item">
                                            <div class="selfservice-products-summary-list__price">
                                                <span>${value.quantity}x ${value.name}</span>
                                                <span>${price} ${type === 'buy' ? window.translator.trans('selfservice.products.price_once') : window.translator.trans('selfservice.products.price_per_months')}</span>
                                            </div>
                                            <div class="selfservice-products-summary-list__note">${note}</div>
                                        </li>`;
            }

        });

        currentSummaryItems += `<li class="selfservice-products-summary-list__item selfservice-products-summary-list__item--total">
                                    <div class="selfservice-products-summary-list__price">
                                        <span>${window.translator.trans('selfservice.products.total_per_month')}: </span>
                                        <span>${summaryData.currentSummary.totalPrice} ${window.translator.trans('selfservice.products.price_per_months')}</span>
                                    </div>
                                    <div class="selfservice-products-summary-list__note">${summaryData.customer.alternative_payment ? window.translator.trans('selfservice.products.paid_via_alt_payment') : ''}</div>
                                </li>`;

        if (totalBuyPrice.length > 0) {
            currentSummaryItems += `<li class="selfservice-products-summary-list__item selfservice-products-summary-list__item--empty"></li>`;
        }

        newSummaryItems += `<li class="selfservice-products-summary-list__item selfservice-products-summary-list__item--title">${window.translator.trans('selfservice.products.new_products_prices')}</li>`;

        $.each(summaryData.newSummary.products, function (index, value) {
            let price = parseFloat(value.price).toFixed(2).split('.').join(',');
            let collective = value.hasOwnProperty('collective') && value.collective;
            let note = '';
            let type = value.hasOwnProperty('type') ? value.type : 'subscription';

            if (collective) {
                newSummaryItems += `<li class="selfservice-products-summary-list__item">
                                        <div class="selfservice-products-summary-list__price">
                                            <span>${value.quantity}x ${value.name}</span>
                                            <span>${window.translator.trans('selfservice.products.paid_via_rent')}</span>
                                        </div>
                                        <div class="selfservice-products-summary-list__note">${note}</div>
                                    </li>`;
            } else {
                newSummaryItems += `<li class="selfservice-products-summary-list__item">
                                        <div class="selfservice-products-summary-list__price">
                                            <span>${value.quantity}x ${value.name}</span>
                                            <span>${price} ${type === 'buy' ? window.translator.trans('selfservice.products.price_once') : window.translator.trans('selfservice.products.price_per_months')}</span>
                                        </div>
                                        <div class="selfservice-products-summary-list__note">${note}</div>
                                    </li>`;
            }
        });

        newSummaryItems += `<li class="selfservice-products-summary-list__item selfservice-products-summary-list__item--total">
                                <div class="selfservice-products-summary-list__price">
                                    <span>${window.translator.trans('selfservice.products.total_per_month')}: </span>
                                    <span>${summaryData.newSummary.totalPrice} ${window.translator.trans('selfservice.products.price_per_months')}</span>
                                </div>
                                <div class="selfservice-products-summary-list__note">${summaryData.customer.alternative_payment ? window.translator.trans('selfservice.products.paid_via_alt_payment') : ''}</div>
                            </li>`;

        if (totalBuyPrice.length > 0) {
            newSummaryItems += `<li class="selfservice-products-summary-list__item">
                                    <div class="selfservice-products-summary-list__price">
                                        <span>${window.translator.trans('selfservice.products.total_once')}: </span>
                                        <span>${totalBuyPrice} ${window.translator.trans('selfservice.products.price_once')}</span>
                                    </div>
                                   
                                    <div class="selfservice-products-summary-list__note">${summaryData.customer.alternative_payment ? window.translator.trans('selfservice.products.paid_via_alt_payment') : ''}</div>
                                </li>`;
        }

        return `<div class="selfservice-products-summary-list-wrapper">
                    <ul class="selfservice-products-summary-list">${currentSummaryItems}</ul>
                    <ul class="selfservice-products-summary-list">${newSummaryItems}</ul>
                </div>`;
    },

    selectSubscription: () => {
        $('.selfservice__select-sub:not(.chosen-product)').on('click', function () {
            const productCard = $(this);
            const subscriptionId = productCard.data('id');

            $('#selfserviceNewSubscriptionId').val(subscriptionId);
            $('.selfservice-subscriptioncard').removeClass('chosen-product');
            productCard.addClass('chosen-product');

            selfservicePlugin.updateProductsSummary();
        });

        $('#selfserviceNewSubscriptionForm').on('submit', function () {
            window.andels.plugins.utils.attacheSpinner();
        });

        $('#selfserviceSubscriptionsSummaryCancel').on('click', function () {
            const selectedSubcriptionId = $('#selfserviceNewSubscriptionId').val();

            $(`.selfservice__select-sub[data-id="${selectedSubcriptionId}"]`).removeClass('chosen-product');
            $('.selfservice-productcard[data-product-active="true"]').addClass('chosen-product');
            $('#selfserviceNewSubscriptionId').val('');
            $('#selfserviceProductsSummary').html('');
            $('.selfservice-products-submit').hide();
        });
    },
    selectAddon: () => {
        selfservicePlugin.attachAddonCounter();

        $('.selfservice__select-addon').on('click', function () {
            const addon_id = $(this).data('id');
            const counterField = $(`.selfservice-addons-input[data-product-conf-id=${addon_id}]`);

            counterField.val(1);
            $(this).toggleClass('chosen-product');

            selfservicePlugin.updateProductsSummary();
        });

        $('.selfservice-addons-input').on('change', function () {
            const counterField = $(this);
            const productConfId = counterField.data('product-conf-id');

            let quantity = parseInt(counterField.val());

            if (quantity > 10) {
                counterField.val(10);
            } else if (quantity < 0) {
                counterField.val(0);
            }

            selfservicePlugin.updateAddonPriceWidget(productConfId);
            selfservicePlugin.updateProductsSummary();
        });

        $('#selfserviceAddonsSummaryCancel').on('click', function () {
            $('.selfservice-addoncard').each(function (index, item) {
                const addonCard = $(item);
                const productConfId = parseInt(addonCard.data('id'));

                const counterField = $(`.selfservice-addons-input[data-product-conf-id="${productConfId}"]`);
                const initialTotalCnt = parseInt(counterField.data('initial-total-quantity'));

                counterField.val(initialTotalCnt);
                selfservicePlugin.updateAddonPriceWidget(productConfId);
            });

            $('.selfservice__select-addon').removeClass('chosen-product');
            $('#selfserviceProductsSummary').html('');
            $('.selfservice-products-submit').hide();
        });

        $('#selfserviceSyncAddonsForm').on('submit', function () {
            window.andels.plugins.utils.attacheSpinner();
        });
    },
    attachAddonCounter: () => {
        $('.selfservice-addon-counter--minus').on('click', function (e) {
            e.stopPropagation();
            const productConfId = $(this).data('for-product-conf-id');
            const counterField = $(`.selfservice-addons-input[data-product-conf-id="${productConfId}"]`);
            let currentValue = counterField.val();

            currentValue > 0 && currentValue--;
            counterField.val(currentValue);
            counterField.trigger('change');
        });

        $('.selfservice-addon-counter--plus').on('click', function (e) {
            e.stopPropagation();
            const productConfId = $(this).data('for-product-conf-id');
            const counterField = $(`.selfservice-addons-input[data-product-conf-id="${productConfId}"]`);
            let currentValue = counterField.val();

            currentValue < 10 && currentValue++;
            counterField.val(currentValue);
            counterField.trigger('change');
        });
    },
    updateAddonPriceWidget: (productConfId) => {
        const counterField = $(`.selfservice-addons-input[data-product-conf-id="${productConfId}"]`);

        const inBundle = counterField.data('bundle');
        const productActive = counterField.data('product-active');

        let oneItemPrice = counterField.data('list-price') ? parseFloat(counterField.data('list-price')) : 0.00;
        let oneItemPriceStr = oneItemPrice.toFixed(2).split('.').join(',');
        let quantity = parseInt(counterField.val());

        let totalPrice = productActive ? 0.00 : parseFloat(counterField.data('initial-total-price'));
        let totalPriceDesc = '';

        if(quantity > 0) {
            if(inBundle) {
                totalPrice += 0.00;
                totalPriceDesc += '1x 0,00 kr.md.'
                quantity--;
            }

            if(quantity > 0) {
                totalPrice += oneItemPrice * quantity;
                totalPriceDesc += totalPriceDesc.length > 0 ? `<br> ${quantity}x ${oneItemPriceStr} kr.md.` : `${quantity}x ${oneItemPriceStr} kr.md.`
            }
        }

        totalPrice = totalPrice.toFixed(2).split('.').join(',');

        $(`[data-price-product-conf-id="${productConfId}"]`).html(totalPrice);
        $(`[data-price-details-product-conf-id="${productConfId}"]`).html(totalPriceDesc);
    },
    initTerminationSection: () => {
        $('#account_termination_resident_value').on('change', function () {
            if ($(this).is(':checked')) {
                $('#selfserviceNewResidentFields').show();
            } else {
                $('#selfserviceNewResidentFields').hide();
            }
        });

        $('#account_termination_resident_email').on('blur', function () {
            const emailField = $(this);
            const errorsBox = emailField.siblings('.invalid-feedback');
            const value = emailField.val();

            if (value.length > 0) {
                selfservicePlugin.validateField('email', value, emailField, errorsBox)
            }
        });
    },
    validateField: (fieldName, value, fieldElement, errorsBox = null) => {
        $.ajax(
            {
                type: 'POST',
                url: '/selfservice/validate',
                data: {field: fieldName, value: value},
                success: function (response) {
                    fieldElement.removeClass('is-invalid');
                    if (!response.success && errorsBox) {
                        errorsBox.text(response.message);
                        fieldElement.addClass('is-invalid');
                    }
                },
            });
    },
    maskPhone: (phoneField) => {
        if (phoneField.val().length) {
            phoneField.prev().show();
            phoneField.css('padding', '0.375rem 0.75rem 0.375rem 2.7rem');
        } else {
            phoneField.prev().hide();
            phoneField.css('padding', '0.375rem 0.75rem');
        }
    }

};
module.exports = selfservicePlugin;
