class Andels {
  constructor(plugins, jQuery) {
    this.plugins = plugins || {};
    this.jQuery = jQuery;
  }

  get plugins() {
    return this._plugins;
  }

  set plugins(plugins) {
    this._plugins = plugins;
  }

  addPlugin(plugin) {
    if (Object(plugin) === plugin) {
      if (plugin.key) {
        this.plugins[plugin.key] = plugin;
      }
    }
  }

  attachBehaviors() {
    Object.values(this.plugins).forEach(plugin => {
      if (typeof plugin['pluginBehavior'] === 'function') {
        plugin['pluginBehavior'].call(this, this, this.jQuery);
      }
    });
  }
}

module.exports = Andels;
