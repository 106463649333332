const postsPlugin = {
    key: 'posts',

    pluginBehavior: (andels, $) => {
        postsPlugin.from = 1;
        if ($('#post_form').length) {
            postsPlugin.initializeControls('#post_form');
        }

        andels.plugins.utils.processHash();

        postsPlugin.attachDeletePost(andels);

        if ($('.feed__container').length) {
            $(window).on('scroll', function () {
                if ($(window).scrollTop() == $(document).height() - $(window).height()) {
                    const id = $('.feed__container').data('owner');
                    const type = $('.feed__container').data('type');

                    if (postsPlugin.from > 0 && parseInt(id) > 0) {
                        andels.plugins.utils.attacheSpinner();
                        $.ajax({
                            type: 'POST',
                            url: `/posts/scroll/${id}-${type}`,
                            data: {from: postsPlugin.from},
                            success: function (data) {
                                if (!$.isEmptyObject(data)) {
                                    $('.feed__container').append(data);
                                    $.each($(`.iteration-${postsPlugin.from} [id^="comment_create-"]`), function () {
                                        window.attachEnteredSimpleEditor($(this).find('textarea').first());
                                    });
                                    $('.postgallery').each(function (index, value) {
                                        attachLightGallery(value);
                                    });
                                    postsPlugin.from++;
                                } else {
                                    postsPlugin.from = -1;
                                }
                                andels.plugins.utils.removeSpinner();
                            },
                            error: function (data) {
                                if ($.type(data) === 'object') {
                                    $('.feed__container').append(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                                } else if ($.type(data) === 'string') {
                                    $('.feed__container').append(`<span class="alert alert-danger">${data}</span>`);
                                }
                                andels.plugins.utils.removeSpinner();
                            },
                        });
                    }
                }
            });
        }

        $(document).on('click', '.delete', function () {
            const id = $(this).parent('.post-menu--container').data('id');
            $('#deleteConfirmation').find('.send-delete').data('id', id);
            $('#deleteConfirmation').modal('show');
        });

        $(document).on('click', '.edit', function () {
            const id = $(this).parent('.post-menu--container').data('id');
            $.ajax({
                type: 'GET',
                url: `/post/${id}/edit`,
                success: function (data) {
                    $(`.post[data-id=${id}]`).hide();
                    $(`#post-placeholder-${id}`).html(data.html);
                    postsPlugin.initializeControls('#post_form_update');
                    andels.plugins.comments.toggleNotifyTitle();
                    $(`#post-placeholder-${id}`).toggle();
                },
                error: function (data) {
                    $(`.post[data-id=${id}]`).prepend(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                },
            });
        });

        $('.postgallery').each(function (index, value) {
            attachLightGallery(value);
        });

        postsPlugin.attachStoreAuthoredPost(andels);
        postsPlugin.attachPostInSuborganization();
    },

    attachDeletePost: (andels) => {
        $('.send-delete').on('click', function () {
            const id = $(this).data('id');
            $.ajax({
                type: 'DELETE',
                url: `/post/${id}`,
                success: function () {
                    andels.plugins.utils.trackUmamiEvent('Delete a post', {post_id: id});
                    window.location.reload();
                },
                error: function (data) {
                    $(`.post[data-id=${id}]`).prepend(`<span class="alert alert-danger">${data.responseJSON.error}</span>`);
                },
            });
        });
        $('#deleteConfirmation').on('hide.bs.modal', function () {
            $('#deleteConfirmation').find('.send-delete').data('id', '')
        });
    },
    initializeControls: (form) => {
        $('.upload-link').on('click', function () {
            $(`${form} #dropzone`).toggle();
        });
        $(`${form} #cancel_post`).on('click', function () {
            $(this).parents('.edit__placeholder').prev('.post').show();
            $('.edit__placeholder').html('').hide();

        });

        let simpleMDE;
        window.attachPostEditor($(`${form} textarea`)[0])
            .then(editor => {
                simpleMDE = editor;
                const plugin = andels.plugins.utils.attacheDropzone(`${form} #dropzone`, postsPlugin.dropZoneOptions(simpleMDE, $(form)));
                window.postDropzone = plugin;
                postsPlugin.attacheImageHandler(plugin);
                postsPlugin.attachStorePost(plugin, $(form), simpleMDE);
                window.andels.plugins.utils.linkPreviewStyles()
            });

    },

    dropZoneOptions: (editor, form) => {
        return {
            url: $(form).attr('action'),
            maxFilesize: 100,
            parallelUploads: 8,
            acceptedFiles: ".jpeg,.jpg,.png,.gif,.mp4,.3gpp,.3gp,.3gpp2,.pdf,.doc,.docx,.txt,.odt,.xls,.xlsx",
            uploadMultiple: true,
            paramName: 'files',
            timeout: 60000,
            autoProcessQueue: false,
            addRemoveLinks: true,
            init: function () {
                this.on('sending', function (file, xhr, formData) {
                    andels.plugins.utils.attacheSpinner();
                    form.find('#description').val(editor.getData());
                    const data = form.serializeArray();
                    $.each(data, function (key, el) {
                        formData.append(el.name, el.value);
                    });
                });
                this.on('addedfile', function () {
                    $('.alert.alert-danger').remove();
                });
            },
            success: function (file, response) {
                if (response) {
                    window.location.href += `#${response}`;
                }
                window.location.reload();
            },
            error: function (file, response) {
                $('.alert.alert-danger').remove();
                if ($.type(response) === 'object') {
                    form.find('#post').val(response.id);
                    response = response.message;
                }
                form.find('#dropzone').prepend(`<div class="alert alert-danger">${response}</div>`);
                andels.plugins.utils.removeSpinner();
                return false;
            }
        };
    },

    attachStorePost: function (dropZone, form, editor) {
        form.find(".submit_post").click(function (e) {
            const author_id = $(this).data('author-id');
            const author_type = $(this).data('author-type');

            form.find('[name="author_type"]').first().val('');
            form.find('[name="author_id"]').first().val('');
            if (author_id !== undefined && author_type !== undefined) {
                form.find('[name="author_type"]').first().val(author_type);
                form.find('[name="author_id"]').first().val(author_id);
            }

            e.preventDefault();
            if (dropZone && dropZone.files && dropZone.files.length) {
                if (!dropZone.getQueuedFiles().length) {
                    for (let i = 0, l = dropZone.files.length; i < l; i++) {
                        dropZone.files[i].status = 'queued';
                        dropZone.files[i].upload.progress = 0;
                        dropZone.files[i].upload.bytesSent = 0;
                    }
                }
                andels.plugins.utils.trackUmamiEvent('Create a post')
                dropZone.processQueue();
            } else {
                if (editor.getData() || form.find('[name^="delete_file"]').length) {
                    andels.plugins.utils.attacheSpinner();
                    andels.plugins.utils.trackUmamiEvent('Create a post')
                    form.submit();
                } else {
                    form.find('.ck.ck-editor')
                        .addClass('rounded')
                        .addClass('border')
                        .addClass('border-danger');
                }
            }
        });
    },

    attacheImageHandler: function (dropzone) {
        $(document).on('paste', function(event) {
            const items = (event.clipboardData || event.originalEvent.clipboardData).items;
            let file = null;

            for (let i = 0; i < items.length; i++) {
                if (items[i].type.indexOf('image') !== -1) {
                    file = items[i].getAsFile();
                    break;
                }
            }

            if (file !== null) {
                dropzone.addFile(file);
                $(dropzone.element).show();
            }
        });
    },

    attachStoreAuthoredPost: () => {
        $(document).on('click', '.store-authored-post', function () {
            const form = $(this).closest('form');
            const author_id = $(this).data('author-id');
            const author_type = $(this).data('author-type');

            form.find('[name="author_id"]').first().val('');
            form.find('[name="author_type"]').first().val('');
            if (author_id !== undefined && author_type !== undefined) {
                form.find('[name="author_type"]').first().val(author_type);
                form.find('[name="author_id"]').first().val(author_id);
            }

            andels.plugins.utils.trackUmamiEvent('Create a post');

            form.submit();
        });
    },
    attachPostInSuborganization: () => {
        $('.repost-to-suborg').on('click', function () {
            const id = $(this).parent('.post-menu--container').data('id');
            $('[name="post_id"]').val(id);
            $('#repostToSuborg').modal('show');
        });
    }
};
module.exports = postsPlugin;
