const mobilePlugin = {
    key: 'mobile',

    pluginBehavior: (andels, $) => {
        mobilePlugin.showMobileMenu(andels);
        mobilePlugin.showGroupSections();
        mobilePlugin.showMobileFilters();
        mobilePlugin.showBlock(andels);
        $('.welcome--menu a').on('click', function () {
            $('.welcome--menu').hide();
            andels.plugins.utils.removeSpinner();
        });
    },
    showBlock: (andels) => {
        $('#show__block').on('click', function () {
            let className = $(this).data('target');
            $(`.${className}`).show();
            andels.plugins.utils.attacheLoader();
        });
        $('#hide__block').on('click', function () {
            let className = $(this).data('target');
            $(`.${className}`).hide();
            andels.plugins.utils.removeSpinner();
        });
    },
    showMobileMenu: (andels) => {
        $('#show__nav--mobile').on('click', () => {
            $('#sidebar__left').show();
            andels.plugins.utils.attacheLoader();
        });
        $('.mobile__menu--close #close_menu').on('click', () => {
            $('#sidebar__left').hide();
            andels.plugins.utils.removeSpinner();
        });
    },
    showMobileFilters: () => {
        $('#show_filter').on('click', function () {
            $(`#${$(this).data('toggle')}`).show();
            $('.marketplace__filter--show').parent().hide();
        });
        $('#close_filter').on('click', function () {
            $(`#${$(this).data('toggle')}`).hide();
            $('.marketplace__filter--show').parent().show();
        });
    },
    showGroupSections: () => {
        $('.group__management--mobile .link--grey-blue').on('click', function () {
            const active = $(this).attr('id');
            const old = $('.group__management--mobile .link--grey-blue.active').attr('id');
            $('.group__management--mobile .link--grey-blue').removeClass('active');
            $(this).addClass('active');
            $(`[data-toggle=${old}]`).hide();
            $(`[data-toggle=${active}]`).show();
        });
    }
};
module.exports = mobilePlugin;
