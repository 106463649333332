import {Calendar} from '@fullcalendar/core/main';
import dayGridPlugin from '@fullcalendar/daygrid/main';
import timeGridPlugin from '@fullcalendar/timegrid/main';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';

function dateParse(date) {
    //DD-MM-YYYY HH:mm:ss
    let YYYY = date.getFullYear();
    let MM = parseInt(date.getMonth()) + 1;
    if (MM < 10) {
        MM = '0' + MM;
    }
    let DD = date.getDate();
    if (DD < 10) {
        DD = '0' + DD;
    }
    let HH = date.getHours();
    if (HH < 10) {
        HH = '0' + HH;
    }
    let mm = date.getMinutes();
    if (mm < 10) {
        mm = '0' + mm;
    }
    let ss = date.getSeconds();
    if (ss < 10) {
        ss = '0' + ss;
    }
    return {DD: DD, MM: MM, YYYY: YYYY, HH: HH, mm: mm, ss: ss};
}

function dateFormat(date) {
    const obj = dateParse(date);
    return `${obj.DD}-${obj.MM}-${obj.YYYY}`;
}

function timeFormat(date) {
    const obj = dateParse(date);
    return `${obj.HH}:${obj.mm}`;
}

function rangeFormat(start, end) {
    const objstart = dateParse(start);
    const objend = dateParse(end);
    let month = new Date(start);
    const locale = $('html').attr('lang');
    const mstart = month.toLocaleString(locale, {month: 'long'});
    month = new Date(end);
    const mend = month.toLocaleString(locale, {month: 'long'});
    if (objend.MM == objstart.MM && objstart.DD == objend.DD) {
        return `${objstart.HH}:${objstart.mm} - ${objend.HH}:${objend.mm} ${objstart.DD}. ${mstart} ${objstart.YYYY}`;
    }

    if (objstart.HH == '00' && objstart.mm == '00' && objend.HH == '00' && objend.mm == '00') {
        return `${objstart.DD}. ${mend} - ${objend.DD}. ${mstart} ${objstart.YYYY}`;
    }
    return `${objstart.HH}:${objstart.mm} ${objstart.DD} ${mstart} - ${objend.HH}:${objend.mm} ${objend.DD}. ${mend} ${objend.YYYY}`;
}

function getValidDateRange() {
    let minDate = new Date();
    let maxDate = new Date();

    minDate.setHours(0, 0, 0, 0);
    maxDate.setHours(0, 0, 0, 0);
    maxDate.setMonth(maxDate.getMonth() + 1);

    return {minDate, maxDate};
}

function bookingContent(info) {
    $('#bookingDetails .booking__content').html(`
              <div>${rangeFormat(info.event.start, info.event.end, true)}</div>
              <div>${info.event.extendedProps.description}</div>
              <div class="bold font-12 pt-2">${info.event.extendedProps.author}</div>
            `);
    $('#bookingDetails').css('top', info.jsEvent.pageY - 50).css('left', info.jsEvent.pageX - 150).show();
}

const calendarEl = document.getElementById('calendar');
if ($('#time_from').length && $('#from_date').length) {
    andels.plugins.utils.calendarTimePicker('time_from', 'time_to');
    andels.plugins.utils.calendarDatePicker('from_date', 'to_date');
}
if (calendarEl) {
    const calendar = new Calendar(calendarEl, {
        locale: window.lang,
        plugins: [dayGridPlugin, timeGridPlugin, momentPlugin, interactionPlugin],
        selectable: true,
        allDaySlot: false,
        header: {center: 'dayGridMonth,timeGridWeek,timeGridDay'},
        buttonText: {
            today: window.translator.trans('js.today'),
            month: window.translator.trans('js.month'),
            week: window.translator.trans('js.week'),
            day: window.translator.trans('js.day')
        },
        views: {
            dayGridMonth: {
                titleFormat: '{{D}. MMMM} YYYY'
            },
            timeGridWeek: {
                titleFormat: '{D}. MMMM YYYY'
            },
            timeGridDay: {
                titleFormat: 'D. MMMM YYYY'
            },
        },
        validRange: {
            start: getValidDateRange().minDate,
        },
        events: '/scheduler',
        select: function (arg) {
            $.when(andels.plugins.facility.buildCreateBookingForm())
                .done(function () {
                    $('#bookFacility').find('#from_date').val(dateFormat(arg.start));
                    $('#bookFacility').find('#to_date').val(dateFormat(arg.end));
                    // $('#bookFacility').find('#time_from').val(timeFormat(arg.start));
                    // $('#bookFacility').find('#time_to').val(timeFormat(arg.end));
                });
        },
        eventClick: function (info) {
            info.jsEvent.preventDefault();
            if (info.event.id !== '#' && info.event.extendedProps.author_id == $('#currentUser').val()) {
                andels.plugins.facility.buildEditBookingForm(info.event.id);
            } else {
                bookingContent(info);
            }
        },
    });

    calendar.render();
}


const rentCalendarEl = document.getElementById('rent_calendar');
if (rentCalendarEl) {
    const rentCalendar = new Calendar(rentCalendarEl, {
        locale: window.lang,
        plugins: [dayGridPlugin, timeGridPlugin, momentPlugin, interactionPlugin],
        selectable: true,
        allDaySlot: false,
        header: {center: 'dayGridMonth,timeGridWeek,timeGridDay'},
        buttonText: {
            today: window.translator.trans('js.today'),
            month: window.translator.trans('js.month'),
            week: window.translator.trans('js.week'),
            day: window.translator.trans('js.day')
        },
        views: {
            dayGridMonth: {
                titleFormat: '{{D}. MMMM} YYYY'
            },
            timeGridWeek: {
                titleFormat: '{D}. MMMM YYYY'
            },
            timeGridDay: {
                titleFormat: 'D. MMMM YYYY'
            },
        },
        eventSources: [
            {
                url: '/announcement/rentscheduler',
                extraParams: {
                    announcement: $('[data-announcement-id]').data('announcement-id')
                }
            }
        ],
        validRange: {
            start: getValidDateRange().minDate,
        },

        select: function (arg) {
            let announcement = $('#bookRent').find('[name="announce_id"]').val();
            let from_date = dateFormat(arg.start);
            let to_date = dateFormat(arg.end);
            let time_from = timeFormat(arg.start);
            let time_to = timeFormat(arg.end);

            $('#bookRent').find('#from_date').val(from_date);
            $('#bookRent').find('#to_date').val(to_date);
            $('#bookRent').find('#time_from').val(time_from);
            $('#bookRent').find('#time_to').val(time_to);

            if (announcement) {
                andels.plugins.business.getRentPrice(announcement, from_date, to_date, time_from, time_to);
            }

            $('#bookRent').modal('show');
        },
        eventClick: function (info) {
            info.jsEvent.preventDefault();
            if (
                info.event.id !== '#' &&
                (
                    info.event.extendedProps.seller_id == info.event.extendedProps.current_user ||
                    (info.event.extendedProps.renter_id == info.event.extendedProps.current_user && !info.event.extendedProps.confirmed)
                )
            ) {
                $.ajax({
                    type: 'GET',
                    url: `/announcement/rent/${info.event.id}/edit`,
                    success: function (data) {
                        $('#bookRent .modal-body').html(data);
                        andels.plugins.utils.calendarTimePicker('time_from', 'time_to');
                        andels.plugins.utils.calendarDatePicker('from_date', 'to_date');
                        if (!$('#destroyBooking').length) {
                            const delete_btn = window.translator.trans('form.delete');
                            $('#bookRent .modal-footer')
                                .prepend(`<div class="mr-4 pr-4 border-right destroy__container">
                                            <button type="button" class="btn btn-danger" id="destroyBooking">${delete_btn}</button>
                                        </div>`);
                        }
                        $('#bookRent').modal('show');
                        $('#destroyBooking').on('click', function () {
                            $('#deleteRentBookingConfirmation [name="delete_rent_id"]').val(info.event.id);
                            $('#bookRent').modal('hide');
                            $('#deleteRentBookingConfirmation').modal('show');
                        });
                    },
                    error: function () {
                        bookingContent(info);
                    },
                });
            }
        },
    });

    rentCalendar.render();

    $(document).on(
        "dp.change",
        "#bookRentForm #time_from, #bookRentForm #time_to, #bookRentForm #from_date, #bookRentForm #to_date",
        function (e) {
            if($('#bookRent').hasClass('show')) {
                let fromDate = $('#bookRentForm #from_date').val();
                let fromTime = $('#bookRentForm #time_from').val();
                let toDate = $('#bookRentForm #to_date').val();
                let toTime = $('#bookRentForm #time_to').val();
                let announcement = $('#bookRent').find('[name="announce_id"]').val();

                if (announcement) {
                    andels.plugins.business.getRentPrice(announcement, fromDate, toDate, fromTime, toTime);
                }
            }
        });

    $('#bookRent').on('hide.bs.modal', function () {
        $('#bookRent .destroy__container').remove();
        $('#bookRent .error').hide();
        $('#bookRent input').removeClass('is-invalid');
    });

    $('.rent-book').on('click', function () {
        let valid = true;
        $.each(['#from_date', '#to_date', '#time_to', '#time_from'], function (index, value) {
            if (!$('#bookRent').find(value).val()) {
                valid = false;
                $('#bookRent').find(value).addClass('is-invalid');
            }
        });
        if (valid) {
            $.ajax({
                url: '/announcement/rent/verify',
                data: $('#bookRentForm').serialize(),
                success: function () {
                    $('#bookRentForm').submit();
                },
                error: function (data) {
                    $('#bookRent .error').html(data.responseJSON.error);
                    $('#bookRent .error').show();
                }
            });

        }

    });
}

const organizationCalendar = document.getElementById('organizationCalendar');
if (organizationCalendar) {
    const owner_id = $('[name="facility_owner_id"]').val();
    const owner_type = $('[name="facility_owner_type"]').val();

    const calendar = new Calendar(organizationCalendar, {
        locale: window.lang,
        plugins: [dayGridPlugin, timeGridPlugin, momentPlugin, interactionPlugin],
        selectable: true,
        allDaySlot: false,
        header: {center: 'dayGridMonth,timeGridWeek,timeGridDay'},
        buttonText: {
            today: window.translator.trans('js.today'),
            month: window.translator.trans('js.month'),
            week: window.translator.trans('js.week'),
            day: window.translator.trans('js.day')
        },
        views: {
            dayGridMonth: {
                titleFormat: '{{D}. MMMM} YYYY'
            },
            timeGridWeek: {
                titleFormat: '{D}. MMMM YYYY'
            },
            timeGridDay: {
                titleFormat: 'D. MMMM YYYY'
            },
        },
        validRange: {
            start: getValidDateRange().minDate,
        },
        eventSources: [
            {
                url: '/scheduler/organization',
                extraParams: {
                    owner_id: owner_id,
                    owner_type: owner_type
                }
            }
        ],
        select: function (arg) {
            $.when(andels.plugins.facility.buildCreateBookingForm(owner_id, owner_type))
                .done(function () {
                    $('#bookFacility').find('#from_date').val(dateFormat(arg.start));
                    $('#bookFacility').find('#to_date').val(dateFormat(arg.end));
                    // $('#bookFacility').find('#time_from').val(timeFormat(arg.start));
                    // $('#bookFacility').find('#time_to').val(timeFormat(arg.end));
                });
        },
        eventClick: function (info) {
            info.jsEvent.preventDefault();
            if (info.event.id !== '#' && info.event.extendedProps.author_id == $('#currentUser').val()) {
                andels.plugins.facility.buildEditBookingForm(info.event.id);
            } else {
                bookingContent(info);
            }
        },
    });

    calendar.render();
}


